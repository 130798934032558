import React, { useCallback, useState, FC, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { OperatorPlaceApi, OperatorGroupApi } from '@just-ai/api/dist/generated/Aimychat';
import { useToggle } from '@just-ai/just-ui';

import { t } from 'localization';
import { axios } from 'pipes/functions';

import { OperatorSkillConfig } from 'modules/TemplatesWizard/types';
import SkillCollapseContainer from 'modules/TemplatesWizard/primitives/SkillCollapseContainer';

import { BaseSkill, ViewComponentProps } from '../BaseSkill';
import OperatorTypeRadioBlock, {
  OperatorBlockProps,
  OperatorAimychatBlock,
  OperatorSendContactsBlock,
  OperatorDontSendContactsBlock,
  OperatorType,
  OperatorJivoBlock,
} from './OperatorTypeRadioBlock';

import styles from './styles.module.scss';

const typeFieldsMap: Record<OperatorType, FC<OperatorBlockProps> | null> = {
  [OperatorType.AIMYCHAT]: OperatorAimychatBlock,
  [OperatorType.JIVO]: OperatorJivoBlock,
  [OperatorType.SEND_CONTACTS]: OperatorSendContactsBlock,
  [OperatorType.DONT_SEND_CONTACTS]: OperatorDontSendContactsBlock,
};

interface SkillVewProps extends ViewComponentProps {
  skill: OperatorSkill;
}
const SkillVew = React.memo(({ skill, index }: SkillVewProps) => {
  useEffect(() => {
    skill.onMount();
  }, [skill]);

  const [currentOperatorType, setCurrentOperatorType] = useState<OperatorType>(
    skill.initialValues?.operatorType || OperatorType.DONT_SEND_CONTACTS
  );
  const defaultValues = useRef<Record<string, any>>({});
  const innerState = useRef<Record<string, any>>({
    operatorType: currentOperatorType,
    ...(skill.initialValues ?? {}),
  });
  const [isWarningModalOpened, openWarningModal, closeWarningModal] = useToggle(false);
  const [isShowErrors, showError, hideError] = useToggle(false);
  const blocksErrors = useRef<Record<string, any>>({});

  const onErrors = useCallback(
    (envName: string, error: any | null) => {
      if (error === null) {
        delete blocksErrors.current[envName];
      } else {
        blocksErrors.current[envName] = error;
      }
      skill.isValid = isEmpty(blocksErrors.current);
    },
    [skill]
  );

  const onValidate = useCallback(() => {
    if (blocksErrors.current['notConnected']) {
      openWarningModal();
      return false;
    }
    !skill.isValid ? showError() : hideError();
    return skill.isValid;
  }, [hideError, openWarningModal, showError, skill.isValid]);

  const onChange = useCallback(
    (value: string, name: string, isDefaultValue: boolean) => {
      if (!isDefaultValue) {
        innerState.current[name] = value;
        skill.notify('onFieldsChange', { value: innerState.current, isDefaultValue });
        const operatorNotConnectedErrorExist =
          [OperatorType.JIVO, OperatorType.AIMYCHAT].includes(innerState.current.operatorType) &&
          !innerState.current.connected;
        onErrors('notConnected', operatorNotConnectedErrorExist || null);
        return;
      }
      defaultValues.current[name] = value;
      skill.notify('onFieldsChange', { value: defaultValues.current, isDefaultValue });
    },
    [onErrors, skill]
  );

  const changeOperatorType = useCallback(
    (operatorType: OperatorType) => {
      skill.deleteAllOperatorsAndChannels();
      setCurrentOperatorType(operatorType);
      innerState.current = { operatorType };
      defaultValues.current = {};
    },
    [skill]
  );

  const FieldsCmp = typeFieldsMap[currentOperatorType];

  return (
    <SkillCollapseContainer onValidate={onValidate} skill={skill} index={index} wrapperClassName={styles.OperatorSkill}>
      <div style={{ marginTop: 8 }}>{skill.skillConfig.description}</div>
      <OperatorTypeRadioBlock selected={currentOperatorType} onChange={changeOperatorType} />
      {FieldsCmp && (
        <FieldsCmp
          skill={skill}
          isWarningModalOpened={isWarningModalOpened}
          closeWarningModal={closeWarningModal}
          onChange={onChange}
          onErrors={onErrors}
          initialValues={skill.initialValues?.operatorType === currentOperatorType ? skill.initialValues : undefined}
          isShowErrors={isShowErrors}
        />
      )}
      <div>{t('OperatorSkill:BottomText')}</div>
    </SkillCollapseContainer>
  );
});

type InnerState = {
  operators: any[];
  channels: any[];
  hasAimyChatConnect?: boolean;
  hasJivoConnect?: boolean;
};
export class OperatorSkill extends BaseSkill<InnerState> {
  private operatorPlaceApi: OperatorPlaceApi;
  private operatorGroupApi: OperatorGroupApi;

  constructor(public skillConfig: OperatorSkillConfig) {
    super(skillConfig);
    this.operatorPlaceApi = new OperatorPlaceApi({}, '');
    this.operatorGroupApi = new OperatorGroupApi({}, '');
    this.innerState = {
      operators: [],
      channels: [],
    };
  }

  onMount = () => {
    this.syncOperatorsAndChannels();
  };

  syncOperatorsAndChannels() {
    this.getOperators();
    this.getChannels();
  }

  getOperators = async () => {
    if (!this.projectId) return;
    this.notify('loading', true);
    const { data: operators } = await axios
      .get<any[]>(`/restapi/botconfig/project/${this.projectId}/livechat`)
      .finally(() => this.notify('loading', false));
    this.skillInnerStateChange({
      operators,
      hasAimyChatConnect: operators.some(channel => channel.type === 'AIMYCHAT'),
    });
  };

  getChannels = async () => {
    if (!this.projectId) return;
    this.notify('loading', true);
    const { data: channels } = await axios
      .get<any[]>(`/restapi/botconfig/project/${this.projectId}`)
      .finally(() => this.notify('loading', false));
    this.skillInnerStateChange({
      channels,
      hasJivoConnect: channels.some(channel => channel.channelType === 'INCOMING_JIVOSITE' && !channel.removed),
    });
  };

  async deleteAllOperatorsAndChannels() {
    this.isDone = false;
    const results = await Promise.allSettled([
      ...this.innerState.operators.map(operator => axios.delete(`/restapi/botconfig/livechat/${operator.id}`)),
      ...this.innerState.channels.map(channel => axios.delete(`/restapi/botconfig/${channel.id}`)),
    ]);
    results.forEach(result => {
      if (result.status === 'rejected') {
        console.error(`Error when deleting operators and channels is OperatorSkill. Error: ${result.reason}`);
      }
    });

    this.skillInnerStateChange({
      operators: [],
      channels: [],
      hasJivoConnect: false,
      hasAimyChatConnect: false,
    });
  }

  connectToAimychat = async (product: 'tovieDS' | 'aimychat') => {
    if (!this.projectId || !this.accountId) return;
    const [{ data: operatorPlace }, { data: operatorGroups }] = await Promise.all([
      this.operatorPlaceApi.getOperatorPlace(this.accountId),
      this.operatorGroupApi.getOperatorGroups(this.accountId),
    ]);
    if (!operatorPlace.token || operatorGroups.groups.length === 0) return Promise.reject('Error connect to Aimychat');
    return axios
      .post(`/restapi/botconfig/${this.projectId}/livechat`, {
        config: {
          apiKey: operatorPlace.token,
          groupId: operatorGroups.groups[0]?.id,
        },
        description: t('AddChannelCard AIMYCHAT'),
        type: 'AIMYCHAT',
      })
      .finally(() => this.getOperators());
  };

  connectToJivo = async (): Promise<string> => {
    const {
      data: { accessToken, id },
    } = await axios.post(`/restapi/botconfig/${this.projectId}`, {
      botName: 'Jivo',
      senderName: 'Jivo',
      accessToken: '',
      channelType: 'INCOMING_JIVOSITE',
      rollout: 'MANUAL',
      branch: 'master',
      project: { defaultBranch: 'master', contentDirectory: '.' },
      customData: { force_reply: false },
    });
    await axios.post(`/restapi/botconfig/${id}/publish`, null, {
      params: { skipTests: true, runTestsInBackground: false },
    });
    await this.getChannels();
    return accessToken;
  };

  ViewComponent = (props: ViewComponentProps) => {
    return <SkillVew skill={this} {...props} />;
  };
}
