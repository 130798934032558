import React from 'react';
import classes from './NoBotsPlaceholder.module.scss';
import { Icon } from '@just-ai/just-ui';
import localize from 'localization';
//@ts-ignore
import { ChannelIcon } from 'altreidsds';
import { BotTypeType } from 'types';
import { GA } from 'pipes/pureFunctions';
import YouTube from 'react-youtube';
import { isEuroInstance } from '../../../isAccessFunction';

const { translate: t } = localize;
const BOTS_VIDEO = () => {
  return {
    template: isEuroInstance() ? 'YoutubeLink:Chatbots:EuroInstance' : 'YoutubeLink:Chatbots',
    playerVars: {
      autoplay: 0,
    },
    onPlay: () => {
      GA('GAEvent', 'test_onbording_video', 'play');
    },
    onEnd: () => {
      GA('GAEvent', 'test_onbording_video', 'сomplete');
    },
  };
};

const CALLS_VIDEO = () => {
  return {
    template: isEuroInstance() ? 'YoutubeLink:Callbots:EuroInstance' : 'YoutubeLink:Callbots',
    playerVars: {
      autoplay: 0,
    },
    onPlay: () => {
      GA('GAEvent', 'test_onbording_video', 'play');
    },
    onEnd: () => {
      GA('GAEvent', 'test_onbording_video', 'сomplete');
    },
  };
};

const BOT_LINKS = (): any[] => [
  {
    link: 'DocLink botStructure',
    GAEvent: 'how_train_bot_docs',
    label: 'How to train bot',
  },
  {
    link: 'helpLink how to create bot',
    GAEvent: 'how_create_bot_docs',
    label: 'How to create a bot',
  },
];

const COMMON_LINKS = () => [
  {
    link: 'Header: user community link',
    label: 'Dashboard: telegram link label',
  },
  {
    link: 'Header: aimylogic news and updates link',
    label: 'Dashboard: community link label',
  },
];

function NoBotsPlaceholder({
  showCreateModal,
  botType,
  showHelp,
}: {
  showCreateModal: () => void;
  botType: BotTypeType;
  showHelp?: boolean;
}) {
  let video: any;
  video = botType === 'CALLS_BOT' ? CALLS_VIDEO() : BOTS_VIDEO();
  return (
    <div className={classes.container}>
      <button className={classes.button} onClick={showCreateModal} data-test-id='Dashboard.CreateBotButton'>
        <Icon name='farPlus' size='lg' />
        <span>{t(botType === 'CALLS_BOT' ? 'Create calls bot' : 'Create bot')}</span>
      </button>
      {showHelp && (
        <div className={classes.infoContainer}>
          <h3 className={classes.label}>{t('Help')}</h3>
          <div className={classes.youtubeVideo}>
            <YouTube
              className='youtubeVideo'
              videoId={t(video.template)}
              onPlay={video.onPlay}
              onEnd={video.onEnd}
              //@ts-ignore
              opts={{ playerVars: video.playerVars }}
            />
          </div>
          <div className={classes.linkList}>
            {BOT_LINKS().map(({ link, label }) => (
              <a key={link} href={t(link)} target='_blank' rel='noopener noreferrer'>
                {t(label)}
              </a>
            ))}
            <div style={{ marginTop: 25 }} />
            {!isEuroInstance() &&
              COMMON_LINKS().map(({ link, label }) => (
                <a key={link} href={t(link)} className={classes.docsLink} target='_blank' rel='noopener noreferrer'>
                  <ChannelIcon type='telegram' />
                  {t(label)}
                </a>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(NoBotsPlaceholder);
