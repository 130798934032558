import React, { ReactNode, useCallback, useMemo } from 'react';
import { isLanguagesAvailable } from '../../isAccessFunction';

import { FormText, JustSelect } from '@just-ai/just-ui';
import { t } from '../../localization';

export type LanguageOption = {
  label: string;
  value: string;
};
interface LanguageSelectNewProps {
  language?: string;
  setProjectLang: (selected: string) => void;
  className?: string;
  languages: LanguageOption[];
  error: string;
  helperText: ReactNode;
  disabled: boolean;
  isCallBot: boolean;
  dataTestId?: string;
}

const LanguageSelectNew = ({
  language,
  setProjectLang,
  languages: allLanguages,
  error,
  helperText,
  disabled = false,
  dataTestId = '',
}: LanguageSelectNewProps) => {
  const languages = useMemo(() => {
    const availabeLanguages: string[] = isLanguagesAvailable(allLanguages?.map(x => x.value) || []);
    return allLanguages.filter(language => availabeLanguages.includes(language.value));
  }, [allLanguages]);

  const handleChange = useCallback(
    selected => {
      setProjectLang(selected);
    },
    [setProjectLang]
  );

  return (
    <div tabIndex={1}>
      <JustSelect
        onChange={handleChange}
        options={languages}
        value={language}
        disabled={disabled}
        invalid={Boolean(error)}
        position='fixed'
        listStyles={{ zIndex: 1401 }}
        messages={{
          NOT_FOUND: t('CreationModal: No search results'),
          EMPTY: t('CreationModal: No search results'),
        }}
        data-test-id={dataTestId}
      />
      {helperText && (
        <FormText tag='small' color='muted'>
          {helperText}
        </FormText>
      )}
    </div>
  );
};

export default LanguageSelectNew;
