export const headerLocalization = {
  'Header: user community': {
    eng: 'User community',
    ru: 'Сообщество пользователей',
    pt: 'Comunidade de usuários',
  },
  'Header: user community link': {
    eng: 'https://t.me/aimylogic',
    ru: 'https://t.me/aimylogic',
    pt: 'https://t.me/aimylogic',
  },
  'Header: news and updates': {
    eng: '#{config.zenflow.productName}. News and updates',
    ru: '#{config.zenflow.productName}. News and updates',
    pt: '#{config.zenflow.productName}. Notícias e atualizações',
  },
  'Header: news and updates link': {
    eng: 'https://t.me/aimylogic_channel',
    ru: 'https://t.me/aimylogic_channel',
    pt: 'https://t.me/aimylogic_channel',
  },
  'Header: youtube channel label': {
    eng: 'YouTube channel',
    ru: 'Канал на YouTube',
    pt: 'Canal do Youtube',
  },
  'Header: youtube channel link': {
    eng: 'https://www.youtube.com/channel/UCixnNdDQtGIlkocxe-Vp5SQ',
    ru: 'https://www.youtube.com/channel/UCixnNdDQtGIlkocxe-Vp5SQ',
    pt: 'https://www.youtube.com/channel/UCixnNdDQtGIlkocxe-Vp5SQ',
  },
  'Header: personal data  label': {
    eng: 'Privacy policy',
    ru: 'Политика персональных данных',
    pt: 'Política de Privacidade',
  },
  'Header: JAICP AI Global link': {
    eng: 'https://just-ai.com/en/',
    ru: 'https://just-ai.com/',
    pt: 'https://just-ai.com/en/',
  },
  'Header: plan': {
    eng: 'Plan',
    ru: 'Тариф',
    pt: 'Plano',
  },
  'Header: account': {
    eng: 'Account',
    ru: 'Профиль',
    pt: 'Conta',
  },
  'Header: email copied': {
    eng: 'Email copied',
    ru: 'Email скопирован',
    pt: 'E-mail copiado',
  },
  'Header:Title': {
    eng: '#{config.zenflow.productName}',
    ru: '#{config.zenflow.productName}',
  },
};
