import React from 'react';
import localize from 'localization';
import { InputText } from '@just-ai/just-ui';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import classnames from 'classnames';

const { translate: t } = localize;

export default class MattermostEditForm extends BaseEditForm {
  submit = async e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ fetching: true });

    const requiredFields = ['accessToken', 'host'];
    const errors = this.getFormErrors(requiredFields);

    if (errors.length > 0) {
      return this.setState({ errors, fetching: false });
    }

    try {
      const { editableChannel } = this.props;
      editableChannel?.id ? await this.saveChannel() : await this.createChannel();
    } finally {
      this.setState({ fetching: false });
    }
  };

  getFormErrors = fields => {
    return fields.reduce((errors, field) => {
      const value = this.form[field]?.value.trim();
      if (!value) {
        errors.push(t(`ChannelsAdding: field is required`, t(`ChannelsAdding: ${field}`)));
      }
      return errors;
    }, []);
  };

  createChannel = async () => {
    const { createChannel, editableChannel, onClose } = this.props;
    let { name, host, accessToken, senderName } = this.form;
    let botName = name?.value || editableChannel.botName || t(`AddChannelCard ${editableChannel.channelType}`);
    const channelData = {
      ...editableChannel,
      botName,
      senderName: senderName?.value || botName,
      accessToken: accessToken?.value || '',
      channelType: editableChannel.channelType,
      customData: { host: host.value },
      project: {
        ...editableChannel.project,
      },
    };

    await createChannel(channelData);
    onClose();
  };

  saveChannel = async () => {
    const { editChannel, onClose } = this.props;

    const channelData = {
      botName: this.form.name.value,
      senderName: this.form.name.value,

      accessToken: this.form.accessToken?.value || '',
      channelType: this.props.editableChannel.channelType,
      customData: { host: this.form.host?.value },
      rollout: 'MANUAL',
      id: this.props.editableChannel.id,
      branch: 'master',
      project: {
        ...this.props.editableChannel.project,
      },
    };

    await editChannel(channelData);

    onClose();
  };

  renderInputs() {
    const { editableChannel } = this.props;

    return (
      <>
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{t('ChannelsAdding: accessToken')}</p>
          <InputText
            innerRef={input => (this.form.accessToken = input)}
            id='bot_token'
            defaultValue={editableChannel.accessToken}
            placeholder={t('Enter custom', t('ChannelsAdding: accessToken').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{t('ChannelsAdding: host')}</p>
          <InputText
            innerRef={input => (this.form.host = input)}
            id='host'
            defaultValue={editableChannel.customData.host}
            placeholder={t('Enter custom', t('ChannelsAdding: host').toLowerCase())}
          />
        </div>
      </>
    );
  }
}
