import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Icon,
  RadioButton,
  Spinner,
  usePromiseProcessing,
  InputText as JustUiInputText,
  useToggle,
} from '@just-ai/just-ui';

import { t } from 'localization';
import { isEuroInstance } from 'isAccessFunction';

import TextMessage from 'modules/TemplatesWizard/FieldBuilder/fields/components/TextMessage';
import WarningAimychatOperatorModal from './modals/WarningAimychatOperatorModal';
import WarningJivoOperatorModal from './modals/WarningJivoOperatorModal';
import { TemplateWizardField } from 'modules/TemplatesWizard/FieldBuilder/fields';
import WarningJivoGenerateTokenModal from './modals/WarningJivoGenerateTokenModal';

import FieldBuilder from '../../FieldBuilder';
import { useSkillStateSelector } from '../BaseSkill';
import { OperatorSkill } from './index';

import styles from './styles.module.scss';
import { useAppSelector } from 'storeHooks';

export enum OperatorType {
  AIMYCHAT = 'aimychat',
  JIVO = 'jivo',
  SEND_CONTACTS = 'sendContacts',
  DONT_SEND_CONTACTS = 'dontSendContacts',
}
type OperatorTypeRadioBlockProps = {
  selected: OperatorType;
  onChange: (type: OperatorType) => void;
};
function OperatorTypeRadioBlock(props: OperatorTypeRadioBlockProps) {
  return (
    <div className={styles.typeRadioWrapper}>
      <span className={styles.title}>{t('OperatorSkill:OperatorType:Title')}</span>
      <div>
        <RadioButton
          checked={props.selected === OperatorType.AIMYCHAT}
          name='OperatorSkill.operator.type'
          label={t('OperatorSkill:OperatorType:Aimychat')}
          value={OperatorType.AIMYCHAT}
          onChange={props.onChange}
        />
        <span
          className={styles.typeRadioWrapper__hint}
          dangerouslySetInnerHTML={{
            __html: t('OperatorSkill:OperatorType:Aimychat:Description'),
          }}
        />
      </div>
      <RadioButton
        checked={props.selected === ('jivo' as OperatorType)}
        name='OperatorSkill.operator.type'
        label={t('OperatorSkill:OperatorType:Jivo')}
        value={OperatorType.JIVO}
        onChange={props.onChange}
      />
      <RadioButton
        checked={props.selected === ('sendContacts' as OperatorType)}
        name='OperatorSkill.operator.type'
        label={t('OperatorSkill:OperatorType:SendContacts')}
        value={OperatorType.SEND_CONTACTS}
        onChange={props.onChange}
      />
      <RadioButton
        checked={props.selected === ('dontSendContacts' as OperatorType)}
        name='OperatorSkill.operator.type'
        label={t('OperatorSkill:OperatorType:DontSendContacts')}
        value={OperatorType.DONT_SEND_CONTACTS}
        onChange={props.onChange}
      />
    </div>
  );
}

const operatorsSettingsBlockFields = () => [
  {
    type: 'TextArea',
    envName: 'operatorsAvailable',
    title: t('OperatorSkill:OperatorsSettingsBlock:OperatorsAvailable:Title'),
    placeholder: t('OperatorSkill:OperatorsSettingsBlock:OperatorsAvailable:Placeholder'),
    value: '',
  },
  {
    type: 'TextArea',
    envName: 'operatorsNoAvailable',
    title: t('OperatorSkill:OperatorsSettingsBlock:OperatorsIsHighLoaded:Title'),
    placeholder: t('OperatorSkill:OperatorsSettingsBlock:OperatorsIsHighLoaded:Placeholder'),
    value: '',
  },
];
type OperatorsSettingsBlockProps = {
  onChange: (value: any, name: string, isDefault: boolean) => void;
  initialValues?: any;
};
const OperatorsSettingsBlock = React.memo((props: OperatorsSettingsBlockProps) => {
  return (
    <FieldBuilder
      fields={operatorsSettingsBlockFields()}
      initialValues={props.initialValues}
      onChange={props.onChange}
    />
  );
});

export type OperatorBlockProps = {
  skill: OperatorSkill;
  onChange: (value: any, name: any, isDefault: boolean) => void;
  initialValues?: any | undefined;
  isWarningModalOpened: boolean;
  closeWarningModal: () => void;
  onErrors: (envName: string, errors: any | null) => void;
  isShowErrors: boolean;
};
export const OperatorAimychatBlock = React.memo(
  ({ skill, onChange, isWarningModalOpened, closeWarningModal, initialValues }: OperatorBlockProps) => {
    const { cloudDomains } = useAppSelector(store => ({
      cloudDomains: store.AppConfigReducer.cloudDomains,
    }));
    const [connected, setConnected] = useState(!!initialValues?.connected);

    const [connectState, connectInvoke] = usePromiseProcessing(() => {
      closeWarningModal();
      return skill.connectToAimychat(isEuroInstance() ? 'tovieDS' : 'aimychat').then(() => setConnected(true));
    });
    const hasAimyChatConnect = useSkillStateSelector(skill, state => state.hasAimyChatConnect);

    useEffect(() => {
      if (hasAimyChatConnect === undefined) return;
      setConnected(hasAimyChatConnect);
    }, [hasAimyChatConnect]);

    useEffect(() => {
      onChange(connected || hasAimyChatConnect, 'connected', false);
    }, [connected, hasAimyChatConnect, onChange]);

    return (
      <>
        <div className={styles.integrationPanel}>
          <h3 className={styles.title}>{t('OperatorSkill:OperatorAimylogicBlock:Title')}</h3>
          <div>{t('OperatorSkill:OperatorAimylogicBlock:Description')}</div>
          {connectState.loading ? (
            <Button color='secondary' className={styles.loadingBtn} disabled>
              <Spinner size='sm' color='secondary' inline />
              <span>{t('OperatorSkill:OperatorAimylogicBlock:ConnectButton:Loading')}</span>
            </Button>
          ) : connectState.result || hasAimyChatConnect ? (
            <div className={styles.buttonContainer}>
              <Button color='success' className={styles.successBtn}>
                <Icon name='farCheck' color='secondary' />
                {t('OperatorSkill:OperatorAimylogicBlock:ConnectButton:Connected')}
              </Button>
              <a href={`https://${cloudDomains.aimychat?.domain}`} target='_blank' rel='noreferrer'>
                <Button color='link' flat className={styles.linkBtn}>
                  {t('OperatorSkill:OperatorAimylogicBlock:ConnectButton:Connected:LinkText')}
                  <Icon name='farExternalLink' color='link' />
                </Button>
              </a>
            </div>
          ) : (
            <Button color='primary' onClick={connectInvoke}>
              {t('OperatorSkill:OperatorAimylogicBlock:ConnectButton:Connect')}
            </Button>
          )}
        </div>
        <OperatorsSettingsBlock onChange={onChange} initialValues={initialValues} />
        <WarningAimychatOperatorModal
          isOpen={isWarningModalOpened}
          onCancel={closeWarningModal}
          onSubmit={connectInvoke}
        />
        <div className={styles.divider} />
      </>
    );
  }
);

const approveTokenInfo = () => ({
  title: t('OperatorSkill:OperatorJivoBlock:ApproveTokenInfo:Title'),
  description: t('OperatorSkill:OperatorJivoBlock:ApproveTokenInfo::Description'),
});
export const OperatorJivoBlock = React.memo(
  ({ skill, onChange, isWarningModalOpened, closeWarningModal, initialValues }: OperatorBlockProps) => {
    const [isConnected, setConnected] = useToggle(initialValues?.connected);
    const [token, setToken] = useState(initialValues?.token || '');

    const [connectState, connectInvoke] = usePromiseProcessing(() => {
      closeWarningModal();
      return skill.connectToJivo().then(token => setToken(token));
    });

    const copyToken = useCallback(() => {
      return navigator?.clipboard?.writeText(token || '');
    }, [token]);

    useEffect(() => {
      onChange(token, 'token', false);
    }, [token, onChange]);

    useEffect(() => {
      onChange(isConnected, 'connected', false);
    }, [isConnected, onChange]);

    const approveTokenText = approveTokenInfo();
    return (
      <>
        <div className={styles.integrationPanel}>
          <h3 className={styles.title}>{t('OperatorSkill:OperatorJivoBlock:ConnectButton:Title')}</h3>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: t('OperatorSkill:OperatorJivoBlock:ConnectButton:DescriptionFirst') }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: isEuroInstance()
                  ? t('OperatorSkill:OperatorJivoBlock:ConnectButton:DescriptionLast:Tovie')
                  : t('OperatorSkill:OperatorJivoBlock:ConnectButton:DescriptionLast'),
              }}
            />
          </div>
          {token ? (
            <>
              <div className={styles.jivoResult}>
                <JustUiInputText value={token} readOnly />
                <Button color='primary' onClick={copyToken}>
                  {t('OperatorSkill:OperatorJivoBlock:CopyToken')}
                </Button>
              </div>
              <TextMessage info={approveTokenText} />
              {isConnected ? (
                <Button color='success' className={styles.successBtn}>
                  <Icon name='farCheck' color='secondary' />
                  {t('OperatorSkill:OperatorJivoBlock:Connected')}
                </Button>
              ) : (
                <Button color='primary' onClick={setConnected}>
                  {t('OperatorSkill:OperatorJivoBlock:connectedApprove')}
                </Button>
              )}
            </>
          ) : connectState.loading ? (
            <Button color='secondary' className={styles.loadingBtn} disabled>
              <Spinner size='sm' color='secondary' inline />
              <span>{t('OperatorSkill:OperatorAimylogicBlock:ConnectButton:Loading')}</span>
            </Button>
          ) : (
            <Button color='primary' onClick={connectInvoke}>
              {t('OperatorSkill:OperatorJivoBlock:GenerateToken')}
            </Button>
          )}
        </div>
        <OperatorsSettingsBlock onChange={onChange} initialValues={initialValues} />
        {token ? (
          <WarningJivoOperatorModal
            isOpen={isWarningModalOpened}
            onCancel={closeWarningModal}
            onSubmit={() => {
              setConnected();
              closeWarningModal();
            }}
          />
        ) : (
          <WarningJivoGenerateTokenModal isOpen={isWarningModalOpened} onSubmit={closeWarningModal} />
        )}

        <div className={styles.divider} />
      </>
    );
  }
);

const getSendContactBlockFields = () => [
  {
    type: 'textMessage',
    title: t('OperatorSkill:Fields:ClientInfoRequest:Title'),
    description: t('OperatorSkill:Fields:ClientInfoRequest:Description'),
  },
  {
    type: 'TextArea',
    envName: 'startText',
    title: t('OperatorSkill:Fields:StartOrderText:Title'),
    placeholder: t('OperatorSkill:Fields:StartOrderText:Placeholder'),
    value: '',
  },
  {
    type: 'DataCollectionTable',
    envName: 'operatorInfo',
    title: '',
    allowNewRows: true,
    preventDeleteLast: true,
    preventDeleteLastHint: t('SkillsWizard:PreventDelete:Hit'),
    columns: [
      {
        envName: 'name',
        maxLength: 20,
        header: t('OperatorSkill:Fields:OrderInfo:Columns:Name:Header'),
        type: 'select',
        selectOptions: [
          t('OperatorSkill:Fields:OrderInfo:SelectOptions:Name'),
          t('OperatorSkill:Fields:OrderInfo:SelectOptions:Phone'),
        ],
        selectMultiple: false,
        eachRowSelectUniqValue: true,
        arbitrarySelectOptions: true,
        placeholder: t('OperatorSkill:Fields:OrderInfo:Columns:Name:Placeholder'),
      },
      {
        envName: 'value',
        maxLength: 500,
        header: t('OperatorSkill:Fields:OrderInfo:Columns:Value:Header'),
        type: 'text',
        selectOptions: null,
        placeholder: [
          {
            condition: { name: t('OperatorSkill:Fields:OrderInfo:SelectOptions:Name') },
            value: t('OperatorSkill:Fields:OrderInfo:Name:Placeholder'),
          },
          {
            condition: { name: t('OperatorSkill:Fields:OrderInfo:SelectOptions:Phone') },
            value: t('OperatorSkill:Fields:OrderInfo:Phone:Placeholder'),
          },
          { condition: {}, value: t('OperatorSkill:Fields:OrderInfo:Placeholder') },
        ],
      },
    ],
    addRowText: t('OperatorSkill:Fields:OrderInfo:AddRowText'),
    value: [
      {
        name: t('OperatorSkill:Fields:OrderInfo:SelectOptions:Name'),
        value: '',
      },
      {
        name: t('OperatorSkill:Fields:OrderInfo:SelectOptions:Phone'),
        value: '',
      },
    ],
  },
  {
    type: 'TextArea',
    envName: 'verifyQuestionsText',
    title: t('OperatorSkill:Fields:VerifyQuestionsText:Title'),
    placeholder: t('OperatorSkill:Fields:VerifyQuestionsText:Placeholder'),
    helperText: t('OperatorSkill:Fields:VerifyQuestionsText:HelperText'),
    value: '',
  },
  {
    type: 'TextArea',
    envName: 'submitText',
    title: t('OperatorSkill:Fields:SubmitOrderText:Title'),
    placeholder: t('OperatorSkill:Fields:SubmitOrderText:Placeholder'),
    value: '',
  },
  {
    type: 'textMessage',
    title: t('OperatorSkill:Fields:SendManagerInfo:Title'),
    description: t('OperatorSkill:Fields:SendManagerInfo:Description'),
  },
  {
    type: TemplateWizardField.MANAGER_NOTIFY,
    envName: 'sendPlaces',
  },
];
export const OperatorSendContactsBlock = React.memo((props: OperatorBlockProps) => {
  useEffect(() => {
    props.onChange(true, 'connected', false);
  }, [props]);

  return (
    <>
      <FieldBuilder
        fields={getSendContactBlockFields()}
        initialValues={props.initialValues}
        onChange={props.onChange}
        isShowErrors={props.isShowErrors}
        onErrors={props.onErrors}
      />
      <div className={styles.divider} />
    </>
  );
});

const dontSendContactBlockFields = () => [
  {
    type: 'TextArea',
    envName: 'callOperatorAnswer',
    title: t('OperatorSkill:Fields:CallOperatorAnswer:Title'),
    placeholder: t('OperatorSkill:Fields:CallOperatorAnswer:Placeholder'),
    value: '',
  },
];
export const OperatorDontSendContactsBlock = React.memo((props: OperatorBlockProps) => {
  useEffect(() => {
    props.onChange(true, 'connected', false);
  }, [props]);

  return (
    <FieldBuilder fields={dontSendContactBlockFields()} initialValues={props.initialValues} onChange={props.onChange} />
  );
});

export default React.memo(OperatorTypeRadioBlock);
