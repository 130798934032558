import React, { PureComponent } from 'react';

import {
  getAvailableOperatorsChannels,
  getChannelTypes,
  getAvailableBusinessChannels,
  isEuroInstance,
} from 'isAccessFunction';
import { t } from 'localization';
import history from 'appHistory';
import {
  chatChannels,
  voiceChannels as defaultVoiceChannels,
  businessChannels as defaultBusinessChannels,
  commonChannels,
} from 'constants/channelTypes';

import AddChannelCard from './AddChannelCard';
import { AddBitrixModal } from './AddBitrixModal';

import styles from './Channels.module.scss';
import { GA } from '../../../pipes/pureFunctions';

function getWizardChannels() {
  return isEuroInstance()
    ? [commonChannels.CHAT_WIDGET, commonChannels.FACEBOOK, commonChannels.INSTAGRAM, commonChannels.WHATSAPP]
    : [
        commonChannels.CHAT_WIDGET,
        commonChannels.TELEGRAM,
        commonChannels.VK,
        commonChannels.WHATSAPP,
        commonChannels.VIBER,
      ];
}

export default class ChannelsAddCardsList extends PureComponent {
  paper = null;

  state = {
    hover: false,
    channelName: '',
    channelLabel: '',
    showBitrixNoAppExistModal: false,
    showBitrixTooMuchChannelsModal: false,
  };

  componentWillUnmount() {
    this.paper = null;
  }

  toggleBitrixNoAppExistModal = () => {
    this.setState(state => ({ showBitrixNoAppExistModal: !state.showBitrixNoAppExistModal }));
  };

  toggleBitrixTooMuchChannelsModal = () =>
    this.setState({ showBitrixTooMuchChannelsModal: !this.state.showBitrixTooMuchChannelsModal });

  goToInstallBitrixApp = () => {
    const tab = window.open('https://www.bitrix24.ru/apps/?app=justai.aimylogic', '_blank');
    if (!tab) return;
    tab.focus();
    this.toggleBitrixNoAppExistModal();
  };

  handleButtonClick = channelType => () => {
    GA(undefined, 'Channel_Select', undefined, channelType);
    this.props.onAddBtnClick(channelType);
  };

  chooseTariff = () => history.push(`/prices`);

  render() {
    const { currentBot, currentUserTariff, channelsCount, appConfig, isOperatorChannelsExist } = this.props;
    const { showBitrixNoAppExistModal, showBitrixTooMuchChannelsModal } = this.state;

    const language = currentBot?.language || 'EN';

    const availableChannels = getChannelTypes()[language];
    const availableBusinessChannels = getAvailableBusinessChannels()[language];
    let operatorChannels = getAvailableOperatorsChannels()[language];

    const incomingChannels = [...Object.values(chatChannels)].filter(x => availableChannels[x]);
    const voiceChannels = Object.values(defaultVoiceChannels).filter(x => !!availableChannels[x]);
    let businessChannels = isEuroInstance() ? ['INCOMING_JIVOSITE'] : Object.values(defaultBusinessChannels);
    businessChannels = businessChannels.filter(x => Object.values(availableBusinessChannels)?.includes(x));

    operatorChannels = Object.values(operatorChannels).filter(
      x => !businessChannels.includes(x) && !['RETAILCRM', 'TALKME', 'JIVOSITE'].includes(x)
    );

    const getChangedChannelName = channelType => {
      if (channelType === 'CHAT_WIDGET') return 'CHAT_WIDGET_CHANGED';
      return channelType;
    };

    const commonProps = {
      currentUserTariff,
      channelsCount,
      appConfig,
      language,
      loadProjects: this.props.loadProjects,
      accountId: this.props.accountId,
    };

    if (this.props.isInWizard) {
      const allowedChannels = getWizardChannels();
      const wizardChannels = incomingChannels.filter(channelName => allowedChannels.includes(channelName));
      return (
        <div className={styles.inWizardChannelsWrapper}>
          <div className={styles.incomingWrapper}>
            {wizardChannels.map(channelName => (
              <AddChannelCard
                key={`channel_${channelName}_AddingBtn`}
                channel={{
                  createAction: this.handleButtonClick(channelName),
                  label: t(`AddChannelCard ${channelName}`),
                  type: channelName,
                }}
                {...commonProps}
              />
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.channelsWrapper}>
        {incomingChannels.length !== 0 && (
          <div className={styles.incoming}>
            <h3 className={styles.channelTypeLabel}>{t('ChannelsAdding: messengers, social net and api')}</h3>
            {incomingChannels.map(key => (
              <AddChannelCard
                key={`channel_${key}_AddingBtn`}
                channel={{
                  createAction: this.handleButtonClick(key),
                  label: t(`AddChannelCard ${getChangedChannelName(key)}`),
                  type: key,
                }}
                {...commonProps}
              />
            ))}
          </div>
        )}
        {voiceChannels.length !== 0 && (
          <div className={styles.voice}>
            <h3 className={styles.channelTypeLabel}>{t('ChannelsAdding: voice assistants')}</h3>
            {voiceChannels.map(key => (
              <AddChannelCard
                key={`channel_${key}_AddingBtn`}
                channel={{
                  createAction: this.handleButtonClick(key),
                  label: t(`AddChannelCard ${key}`),
                  type: key,
                }}
                {...commonProps}
              />
            ))}
          </div>
        )}
        <div className={styles.businessAndOperators}>
          {businessChannels.length !== 0 && (
            <>
              <h3 className={styles.channelTypeLabel}>{t('ChannelsAdding: business channels')}</h3>
              {businessChannels.map(key => (
                <AddChannelCard
                  key={`channel_${key}_AddingBtn`}
                  toggleBitrixNoAppExistModal={this.toggleBitrixNoAppExistModal}
                  toggleBitrixTooMuchChannelsModal={this.toggleBitrixTooMuchChannelsModal}
                  disabled={isOperatorChannelsExist}
                  channel={{
                    createAction: this.handleButtonClick(key),
                    label: t(`AddChannelCard ${key}`),
                    type: key,
                  }}
                  {...commonProps}
                />
              ))}
            </>
          )}
          {operatorChannels.length !== 0 && (
            <>
              <h3 className={styles.channelTypeLabel}>{t('ChannelsAdding: transfer to operator')}</h3>
              {operatorChannels.map(key => {
                let label = t(`AddChannelCard ${key}`);
                return (
                  <AddChannelCard
                    key={`channel_${key}_AddingBtn`}
                    disabled={isOperatorChannelsExist}
                    channel={{
                      createAction: this.handleButtonClick(key),
                      label,
                      type: key,
                    }}
                    {...commonProps}
                  />
                );
              })}
            </>
          )}
        </div>
        <AddBitrixModal
          appConfig={appConfig}
          showBitrixNoAppExistModal={showBitrixNoAppExistModal}
          showBitrixTooMuchChannelsModal={showBitrixTooMuchChannelsModal}
          goToInstallBitrixApp={this.goToInstallBitrixApp}
          toggleBitrixNoAppExistModal={this.toggleBitrixNoAppExistModal}
          toggleBitrixTooMuchChannelsModal={this.toggleBitrixTooMuchChannelsModal}
          createBitrixAnyway={this.handleButtonClick('BITRIX')}
        />
      </div>
    );
  }
}
