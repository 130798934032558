export const channelsaddingLocalization = {
  'ChannelsAdding: Add webim, livetex and etc to unblock operator channels': {
    eng: 'Connect a business channel or a channel from the “Transfer to the agent” section',
    ru: 'Подключите бизнес-канал или канал из секции «Перевод на оператора»',
    pt: 'Conecte um canal empresarial ou um canal da seção "Transferir para o agente"',
  },
  'ChannelsAdding: Add webim, livetex and etc to unblock operator channels euroInstance': {
    eng: 'Add Operator API to enable the block',
    ru: 'Добавьте Operator API чтобы разблокировать блок',
    pt: 'Adicionar Operator API para ativar o bloco',
  },
  'ChannelsAdding: Add channels to unblock operator channels': {
    eng: 'Add an agent chat or channel to enable the block',
    ru: 'Добавьте чат или канал с оператором, чтобы разблокировать блок',
    pt: 'Adicionar um chat ou canal de agente para ativar o bloqueio',
  },
  'ChannelsAdding: Connections': {
    eng: 'Connections',
    ru: 'Подключения',
    pt: 'Conexões',
  },
  'ChannelsAdding:Channels': {
    eng: 'Channels',
    ru: 'Каналы',
    pt: 'Canais',
    cn: '通道',
  },
  'ChannelsAdding: Setup connections': {
    eng: 'Configure connection',
    ru: 'Настройте подключение',
    pt: 'Configurar conexão',
  },
  'ChannelsAdding:AddNewConnections': {
    eng: 'Connect a new channel',
    ru: 'Подключите новый канал',
    pt: 'Conecte um novo canal',
  },
  'ChannelsAdding:OnlyTariff:SkillMaster': {
    eng: 'With the current subscription plan, you can connect only voice assistance. For other channels, <a className="text-decoration-none" href="$[1]" target="_blank">change your plan</a>',
    ru: 'На вашем тарифе доступны только голосовые ассистенты. Чтобы подключить другие каналы, <a className="text-decoration-none" href="$[1]" target="_blank">смените тариф</a>',
    pt: 'Com o plano de assinatura atual, você pode conectar apenas assistência por voz. Para outros canais, <a className="text-decoration-none" href="$[1]" target="_blank">altere seu plano</a>',
  },
  'ChannelsAdding:OnlyTariff:Jivo': {
    eng: 'With the current subscription plan, you can connect only JivoChat. For other channels, <a className="text-decoration-none" href="$[1]" target="_blank">change your plan</a>',
    ru: 'На вашем тарифе доступен только канал Jivo. Чтобы подключить другие, <a className="text-decoration-none" href="$[1]" target="_blank">смените тариф</a>',
    pt: 'Com o plano de assinatura atual, você pode conectar apenas o JivoChat. Para outros canais, <a className="text-decoration-none" href="$[1]" target="_blank">altere seu plano</a>',
  },
  'ChannelsAdding: Operator': {
    eng: 'Agent',
    ru: 'Оператор',
    pt: 'Agente',
  },
  'ChannelsAdding: You have no connected channels': {
    eng: 'Configure text channel',
    ru: 'Подключите текстовый канал',
    pt: 'Configurar canal de texto',
  },
  'ChannelsAdding: Connect the channel from the list below': {
    eng: 'Chat widget, messenger, social media, or Chat API',
    ru: 'Чат-виджет, мессенджер, соцсеть или Chat API',
    pt: 'Widget de chat, mensageiro, rede social ou API de chat',
  },
  'ChannelsAdding:Connection:NoOperator': {
    eng: 'Connect a channel from the list below',
    ru: 'Подключите канал из списка ниже',
    pt: 'Conecte um canal na lista abaixo',
  },
  'ChannelsAdding: here will be all channels': {
    eng: 'Here all the channels the bot is connected to are displayed',
    ru: 'Здесь будут все каналы, к которым вы подключите бота',
    pt: 'Aqui são exibidos todos os canais aos quais o bot está conectado',
  },
  'ChannelsAdding: to add another operator firstly delete current one': {
    eng: 'If you want to add another agent, remove an existing one',
    ru: 'Чтобы добавить другого оператора, сначала удалите текущего',
    pt: 'Se você quiser adicionar outro agente, remova o agente atual',
  },
  'ChannelsAdding: messengers, social net and api': {
    eng: 'Messengers, social media and API',
    ru: 'Мессенджеры, соцсети и API',
    pt: 'Mensageiros, rede social e API',
  },
  'ChannelsAdding: voice assistants': {
    eng: 'Voice assistants',
    ru: 'Голосовые ассистенты',
    pt: 'Assistentes de voz',
  },
  'ChannelsAdding: business channels': {
    eng: 'Business channels',
    ru: 'Бизнес-каналы',
    pt: 'Canais empresariais',
  },
  'ChannelsAdding: transfer to operator': {
    eng: 'Transfer to the agent',
    ru: 'Перевод на оператора',
    pt: 'Transferir para o agente',
  },
  'ChannelsAdding: operator link helper': {
    eng: 'Learn more about connection',
    ru: 'Читайте подробнее про подключение',
    pt: 'Saiba mais sobre conexão',
  },
  'ChannelsAdding: how to add': {
    eng: 'How to connect $[1]',
    ru: 'Как подключить $[1]',
    pt: 'Como conectar um canal $[1]',
  },
  'ChannelsAdding: appId': {
    eng: 'AppID',
    ru: 'AppID',
    pt: 'AppID',
  },
  'ChannelsAdding: secret': {
    eng: 'AppSecret',
    ru: 'AppSecret',
    pt: 'AppSecret',
  },
  'ChannelsAdding: accessToken': {
    eng: 'Token',
    ru: 'Токен',
    pt: 'Token',
  },
  'ChannelsAdding: host': {
    eng: 'Host',
    ru: 'Хост',
    pt: 'Host',
  },
  'ChannelsAdding: phoneNumberId': {
    eng: 'Phone number ID',
    ru: 'ID номера телефона',
    pt: 'ID do número de telefone',
  },
  'ChannelsAdding: field is required': {
    eng: 'The $[1] field is required',
    ru: 'Поле $[1] является обязательным',
    pt: 'O campo $[1] é obrigatório',
  },
  'ChannelsAdding: ApiKey': {
    eng: 'API key',
    ru: 'Ключ API',
    pt: 'Chave de API',
    cn: 'API密钥',
  },
  'ChannelsAdding: clientUrl': {
    eng: 'Client URL',
    ru: 'Адрес аккаунта',
    pt: 'URL do cliente',
    cn: '客户URL',
  },
  'ChannelsAdding: ApiSecret': {
    eng: 'API Secret',
    ru: 'API Secret',
    pt: 'API Secret',
  },
  'ChannelsAdding: Bearer Token': {
    eng: 'Bearer Token',
    ru: 'Bearer Token',
    pt: 'Bearer Token',
  },
  'ChannelsAdding: Basic Authorization': {
    eng: 'Basic Authorization',
    ru: 'Basic Authorization',
    pt: 'Basic Authorization',
  },
  'ChannelsAdding: type auth': {
    eng: 'Authorization type',
    ru: 'Тип авторизации',
    pt: 'Tipo de autorização',
  },
  'ChannelsAdding: Inbound URL': {
    eng: 'Inbound URL',
    ru: 'Inbound URL',
    pt: 'Inbound URL',
  },
  'ChannelsAdding: Status URL': {
    eng: 'Status URL',
    ru: 'Status URL',
    pt: 'Status URL',
  },
  'ChannelsAdding: Select a vonage account': {
    eng: 'Select a Vonage account',
    ru: 'Выберите аккаунт Vonage',
    pt: 'Selecione uma conta Vonage',
  },
  'ChannelsAdding: Select a vonage account description': {
    eng: 'To connect WhatsApp Business, you need a Vonage account.',
    ru: 'Чтобы подключить WhatsApp Business, вам нужен аккаунт Vonage.',
    pt: 'Para conectar o WhatsApp Business, você precisa de uma conta Vonage.',
  },
  'ChannelsAdding: Select a vonage account request button': {
    eng: 'I do not have a Vonage account',
    ru: 'У меня нет аккаунта Vonage',
    pt: 'Não tenho uma conta Vonage',
  },
  'ChannelsAdding: Select a vonage account exist button': {
    eng: 'Authorize existing account',
    ru: 'Авторизоваться с существующим аккаунтом',
    pt: 'Autorizar conta existente',
  },
  'ChannelsAdding: vonage request title': {
    eng: 'Request for Vonage integration',
    ru: 'Запрос интеграции с Vonage',
    pt: 'Solicitar integração do Vonage',
  },
  'ChannelsAdding: vonage request accepted title': {
    eng: 'Your request has been accepted',
    ru: 'Ваш запрос принят',
    pt: 'Sua solicitação foi aceita',
  },
  'ChannelsAdding: vonage request accepted description': {
    eng: 'We will contact you to help configure a Vonage integration.',
    ru: 'Мы свяжемся с вами и поможем настроить интеграцию с Vonage.',
    pt: 'Entraremos em contato para ajudar a configurar uma integração do Vonage.',
  },
  'ChannelsAdding: vonage request description': {
    eng: 'Provide your contact details if you want to configure the Vonage integration.',
    ru: 'Укажите свои контактные данные, если вы хотите настроить интеграцию с Vonage.',
    pt: 'Forneça seus detalhes de contato se quiser configurar a integração do Vonage.',
  },
  'ChannelsAdding: go to connection button label': {
    eng: 'Go to Connections',
    ru: 'Перейти к Подключениям',
    pt: 'Acessar Conexões',
  },
  'ChannelsAdding: send request': {
    eng: 'Send request',
    ru: 'Отправить запрос',
    pt: 'Enviar solicitação',
  },
  'ChannelsAdding: vonage request name label': {
    eng: 'Name',
    ru: 'Имя',
    pt: 'Nome',
  },
  'ChannelsAdding: Aimychat group': {
    eng: 'Default agent group',
    ru: 'Группа операторов по умолчанию',
    pt: 'Grupo padrão de agentes',
  },
  'ChannelsAdding: Aimychat group placeholder': {
    eng: 'Select group',
    ru: 'Выберите группу',
    pt: 'Selecionar grupo',
  },
  'Auth:Facebook:Continue': {
    eng: 'Continue with Facebook',
    pt: 'Continuar com Facebook',
  },
  'Auth:Facebook:Connection:Fail': {
    eng: 'Connection error',
    pt: 'Erro de conexão',
  },
  'Auth:Facebook:Error:Base': {
    eng: 'An error occurred while connecting. Please try again or contact technical support.',
    ru: 'При подключении возникла ошибка. Попробуйте еще раз или обратитесь в службу технической поддержки.',
  },
  'Auth:Facebook:Account:Fail': {
    eng: 'Account connection error',
    pt: 'Erro de conexão de conta',
  },
  'Auth:Instagram:Connection:Fail': {
    eng: 'Connection error',
    pt: 'Erro de conexão',
  },
  'Auth:Instagram:Account:Fail': {
    eng: 'Account connection error',
    pt: 'Erro de conexão de conta',
  },
  'Facebook:Banner:Message': {
    eng: 'To connect your bot to Facebook, allow #{config.zenflow.productName} to send messages from your page. <a href="$[1]">Learn more about our Facebook integration</a>',
    pt: 'Para conectar seu bot ao Facebook, permita que o #{config.zenflow.productName} envie mensagens a partir da sua página. <a href="$[1]">Aprenda mais sobre nossa integração com o Facebook</a>',
  },
  'Instagram:Banner:Message': {
    eng: 'To connect your bot to Instagram, allow #{config.zenflow.productName} to send messages from your page. <a href="$[1]">Learn more about our Instagram integration</a>',
    pt: 'Para conectar seu bot ao Instagram, permita que o #{config.zenflow.productName} envie mensagens a partir da sua página. <a href="$[1]">Aprenda mais sobre nossa integração com o Instagram</a>',
  },
  'Auth:NoChannels:Instagram': {
    eng: 'The Instagram channel is not connected. While configuring permissions for $[1], you have not specified an Instagram Business Account or a Facebook Page connected to it. Please connect the channel again.',
    ru: 'Канал Instagram не подключен. При настройке разрешений для $[1] вы не выбрали бизнес-аккаунт Instagram или связанную с ним Facebook-страницу. Пройдите процедуру подключения еще раз.',
    pt: 'O canal do Instagram não está conectado. Durante a configuração de permissões para $[1], você não especificou uma Conta Empresarial do Instagram ou uma Página do Facebook para ele. Conecte o canal novamente.',
  },
  'How:Connect:Instagram': {
    eng: 'Read more',
    ru: 'Подробнее',
    pt: 'Leia mais',
  },
  'WhatsApp:Banner:Message': {
    eng: 'To connect your bot to WhatsApp, allow #{config.zenflow.productName} to send messages from your page. <a href="$[1]">Learn more about our WhatsApp integration</a>',
    pt: 'Para conectar seu bot ao WhatsApp, permita que o #{config.zenflow.productName} envie mensagens a partir da sua página. <a href="$[1]">Aprenda mais sobre nossa integração com o WhatsApp</a>',
  },
  'ChannelsAdding:LoadFile': {
    eng: 'Upload file',
    ru: 'Загрузить файл',
    pt: 'Carregar arquivo',
  },
  'ChannelsAdding:LoadFromLink': {
    eng: 'Add link to file',
    ru: 'Добавить ссылку на файл',
    pt: 'Adiciona link para o arquivo',
  },
  'ChannelsAdding:EnterToken': {
    eng: 'Enter token',
    ru: 'Введите токен',
    pt: 'Digite token',
  },
  'ChannelsAdding:EnterPhoneNumberId': {
    eng: 'Enter the phone number ID',
    ru: 'Введите ID номера телефона',
    pt: 'Digite o ID do número de telefone',
  },
  'ChannelsAdding:Telegram:InfoLabel': {
    eng: 'How to get a token',
    ru: 'Как получить токен',
    pt: 'Como obter um token',
  },
  'ChannelsAdding:Telegram:InfoTitle': {
    eng: 'Create a bot account in Telegram:',
    ru: 'Создайте аккаунт бота в Telegram:',
    pt: 'Crie uma conta de bot no Telegram:',
  },
  'ChannelsAdding:Telegram:InfoItem_1': {
    eng: 'Open the $[1] channel and enter the $[2] command.',
    ru: 'Откройте канал $[1] и введите команду $[2].',
    pt: 'Abra o canal $[1] e digite o comando $[2].',
  },
  'ChannelsAdding:Telegram:InfoItem_2': {
    eng: 'Enter a bot name. It will be displayed in the profile description next to the bot’s avatar and in the chat list.',
    ru: 'Придумайте имя для бота. Оно будет отображаться в описании профиля рядом с аватаром бота и в списке диалогов.',
    pt: 'Digite o nome do bot. Ele será exibido na descrição do perfil ao lado do avatar do bot e na lista de chat.',
  },
  'ChannelsAdding:Telegram:InfoItem_3': {
    eng: 'Enter a username for your bot. It will be displayed in a link to the bot. The username must end in bot or _bot.',
    ru: 'Придумайте имя пользователя для бота — его @username в Telegram. Имя будет отображаться в ссылке на бота. Оно должно заканчиваться на bot или _bot.',
    pt: 'Digite o nome de usuário para seu bot. Ele será exibido em um link para o bot. O número de usuário deve terminar com bot ou _bot.',
  },
  'ChannelsAdding:Telegram:InfoItem_4': {
    eng: 'Copy the token from the message and paste it into the field above.',
    ru: 'Скопируйте токен из сообщения и вставьте в поле выше.',
    pt: 'Copie o token da mensagem e cole-o no campo acima.',
  },
  'ChannelsAdding:Telegram:InfoDocsLink': {
    eng: 'Detailed instruction',
    ru: 'Подробная инструкция',
    pt: 'Instrução detalhada',
  },
};
