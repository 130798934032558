import { Component, Fragment } from 'react';
import routes from './routes';
import { bindActionCreators } from 'redux';
import { getAppConfig, getCloudAppOptions } from './actions/appConfig.actions';
import { connect } from 'react-redux';
import Spinner from './components/Spinner';
import localize from './localization';
import { getUserLanguage } from './pipes/pureFunctions';
import 'flag-icon-css/css/flag-icon.css';
import './App.scss';
import './just-emoji.scss';
import { isEuroInstance } from './isAccessFunction';
import { AppLogger } from '@just-ai/logger';

class App extends Component {
  state = {
    load: false,
  };

  componentDidMount() {
    const { fetching, fetched } = this.props;

    const setAppMeta = zenflowData => {
      if (!zenflowData) return;
      document.title = `${zenflowData.companyName} ${zenflowData.productName}`;

      if (Boolean(zenflowData.favicon) && zenflowData.favicon.length > 0) {
        let link = document.querySelector("link[rel*='icon']");
        if (!Boolean(link)) {
          link = document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        if (isEuroInstance()) {
          link.href = '/ds-favicon.ico';
        } else {
          link.href = zenflowData.favicon;
        }
      }
    };

    const loadAppConfig = async () => {
      if (!fetching && !fetched) {
        await this.props.getCloudAppOptions();
        const response = await this.props.getAppConfig();
        if (!response) return;
        await localize.setLocale(
          getUserLanguage(response.value.data.zenflow && response.value.data.zenflow.userCountryIsoCode)
        );
        localize.setAppConfigVariables(response.value.data.zenflow, response.value.data.botadmin);
        localize.setConfigVariables({
          zenflow: {
            helpUrl: response.value.data.zenflow.helpUrl,
            companyName: {
              eng: response.value.data.zenflow.companyName,
              ru: response.value.data.zenflow.companyName,
            },
            productName: {
              eng: response.value.data.zenflow.productName,
              ru: response.value.data.zenflow.productName,
            },
            contactLink: {
              eng: this.props.cloudDomains?.['aimylogic']?.email?.contact,
              ru: this.props.cloudDomains?.['aimylogic']?.email?.contact,
            },
          },
          aimychat: {
            productName: {
              eng: this.props.cloudDomains?.['aimychat'].appTitle,
              ru: this.props.cloudDomains?.['aimychat'].appTitle,
            },
          },
          aimyvoice: {
            productName: {
              eng: this.props.cloudDomains?.['aimyvoice'].appTitle,
              ru: this.props.cloudDomains?.['aimyvoice'].appTitle,
            },
          },
          caila: {
            productName: {
              eng: this.props.cloudDomains?.['caila'].appTitle,
              ru: this.props.cloudDomains?.['caila'].appTitle,
            },
          },
          cc: {
            productName: {
              eng: this.props.cloudDomains?.['cc'].appTitle,
              ru: this.props.cloudDomains?.['cc'].appTitle,
            },
          },
          copilot: {
            productName: {
              eng: this.props.cloudDomains?.['copilot'].appTitle,
              ru: this.props.cloudDomains?.['copilot'].appTitle,
            },
          },
          botadmin: {
            helpUrl: response.value.data.botadmin.helpUrl,
            productName: {
              eng: this.props.cloudDomains?.['jaicp'].appTitle,
              ru: this.props.cloudDomains?.['jaicp'].appTitle,
            },
          },
        });
        await setAppMeta(response.value.data.zenflow);
      }
    };

    loadAppConfig()
      .catch(AppLogger.createErrorHandler('loadAppConfig'))
      .finally(() => {
        this.setState({ load: true });
      });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const currentLang = this.props.language;
    const nextLang = nextProps.language;

    if (currentLang !== nextLang) {
      localize.setLocale(nextLang);
    }
  }

  render() {
    const { load } = this.state;
    return load ? (
      <Fragment>{routes}</Fragment>
    ) : (
      <>
        <Spinner /> <style dangerouslySetInnerHTML={{ __html: `#launcher { display: none !important; }` }} />{' '}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAppConfig: bindActionCreators(getAppConfig, dispatch),
  getCloudAppOptions: bindActionCreators(getCloudAppOptions, dispatch),
});

function mapStateToProps(state) {
  return {
    fetching: state.AppConfigReducer.fetching,
    fetched: state.AppConfigReducer.fetched,
    language: state.CurrentUserReducer.language,
    cloudDomains: state.AppConfigReducer.cloudDomains,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
//add some comment2
