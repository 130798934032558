import React, { useCallback, useEffect, useState, useRef, useContext, ReactText } from 'react';
import { Icon } from '@just-ai/just-ui';
import localize from '../../../localization';
import classNames from 'classnames';
import './tagsFilter.scss';
import { JustSelectMultiple } from '@just-ai/just-ui';
import { OptionType } from '@just-ai/just-ui/dist/JustSelect/types';
import { createOptionFromTag, TagsContext } from '../TagsContext';
import { LabelComponent } from './BotDropDownMenu';
import { LabelComponentType } from '@just-ai/just-ui/dist/JustSelect/JustSelectItem';
import { usePrevious } from '@just-ai/just-ui/dist/utils';

const { translate: t } = localize;

const LabelComponentEmptyList: LabelComponentType = () => {
  return (
    <div className='LabelEmptyList_BotControls'>
      <div className='LabelEmptyList-title'>{t('LabelEmptyList-title')}</div>
    </div>
  );
};

interface TagsFilterInterface {
  onSelect: (values: (string | number)[] | null) => unknown;
}

export function TagsFilter({ onSelect }: TagsFilterInterface) {
  const { allTags } = useContext(TagsContext);
  const outsideClickRef = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState(false);

  const [values, setValues] = useState<ReactText[] | null>(null);
  const [options, setOptions] = useState<OptionType[]>([]);

  const prevAllTags = usePrevious(allTags);

  const handleClickOutside = useCallback(
    e => {
      if (outsideClickRef.current && !outsideClickRef.current.contains(e.target) && document.contains(e.target)) {
        setShow(false);
        onSelect(values);
      }
    },
    [onSelect, values]
  );

  const handleChange = (values: (string | number)[] | null) => {
    setValues(values);
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => document.addEventListener('click', handleClickOutside), 0);
    } else {
      setTimeout(() => document.removeEventListener('click', handleClickOutside), 0);
    }

    return () => {
      setTimeout(() => document.removeEventListener('click', handleClickOutside), 0);
    };
  }, [show, handleClickOutside]);

  useEffect(() => {
    setOptions(createOptionFromTag(allTags));
  }, [allTags, show]);

  useEffect(() => {
    if (values && values.length > 0 && prevAllTags && JSON.stringify(prevAllTags) !== JSON.stringify(allTags)) {
      let newValues: ReactText[] | null = values.filter(value => allTags.findIndex(tag => tag.id === value) > -1);
      newValues = newValues.length === 0 ? null : newValues;
      setValues(newValues);
      onSelect(newValues);
    }
  }, [allTags, prevAllTags, values, onSelect]);

  return (
    <>
      <div className={classNames('tagsFilter', { show: show })} onClick={() => setShow(true)}>
        <Icon name='farFilter' size='md' />
        <span>{t('TagsFilter:tags')}</span>
        {values && <div className='selectedValues'>{values.length}</div>}
      </div>
      <div ref={outsideClickRef} key={values?.length} className='tagsFilterContainer'>
        {show && (
          <JustSelectMultiple
            options={options}
            open={show}
            fullWidth
            onChange={handleChange}
            defaultValue={values as number[]}
            autoFocus
            optionComponent={options.length === 0 ? LabelComponentEmptyList : LabelComponent}
            messages={{
              NOT_FOUND: t('BotControls:not_found'),
              EMPTY: '',
            }}
            optionComponentProps={{
              allTags: allTags,
              options: options,
              values: values || [],
            }}
          />
        )}
      </div>
    </>
  );
}
