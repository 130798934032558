import React, { Component } from 'react';
import { t } from '../../localization';
import { ActionConfirmModal, Button, Grid, AtreidesTypo } from 'altreidsds';
import history from '../../appHistory';
import classes from './MobileWarningModal.module.scss';

export default class MobileWarningModal extends Component {
  state = {
    open: false,
  };

  goToDashboard = () => {
    history.push('/dashboard');
    this.props.onClose();
  };

  render() {
    const { open, onClose, pathname } = this.props;

    return (
      <ActionConfirmModal
        disableBackdropClick={true}
        open={open}
        onClose={onClose}
        classes={{
          modal: classes.modal,
        }}
        dontShowDefaultActions
        modalBottom={
          <Grid container className={classes.bottons} direction='row' spacing={8} justify='flex-end'>
            <Grid item>
              <Button variant='link' onClick={onClose} color='warning'>
                {t('MobileVersion modal stay')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' color='warning' onClick={this.goToDashboard}>
                {t('ContractRequest Go to my dashboard')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={12} style={{ margin: 0 }}>
          <AtreidesTypo style={{ marginTop: 0 }} type='h2'>
            {t(
              pathname.includes('/scenario/') ? 'MobileVersion constuctor modal warning' : 'MobileVersion modal warning'
            )}
          </AtreidesTypo>
          <AtreidesTypo type='span'>{t('MobileVersion constuctor modal info')}</AtreidesTypo>
        </Grid>
      </ActionConfirmModal>
    );
  }
}
