export const verificationModalLocalization = {
  'VerificationModal:Title': {
    eng: 'Account verification',
    ru: 'Подтверждение аккаунта',
  },
  'VerificationModal:BodyText': {
    eng: 'For the security of outbound calls made using #{config.zenflow.productName} telephony, please fill out the form below. We will review this information and inform you once the account has been verified.',
    ru: 'Для безопасности исходящих звонков через телефонию #{config.zenflow.productName} заполните форму ниже. Мы проверим информацию и сообщим об успешно пройденной верификации.',
  },
  'VerificationModal:OrganizationLabel': {
    eng: 'Organization name',
    ru: 'Название организации',
  },
  'VerificationModal:OrganizationPlaceholder': {
    eng: 'Enter the name of your organization or the customer of your project',
    ru: 'Введите название вашей организации или заказчика проекта',
  },
  'VerificationModal:OrganizationErrorText': {
    eng: 'Required field',
    ru: 'Поле обязательно для заполнения',
  },
  'VerificationModal:PurposeLabel': {
    eng: 'Call campaign purpose',
    ru: 'Цели обзвона',
  },
  'VerificationModal:PurposePlaceholder': {
    eng: 'Enter a text',
    ru: 'Введите текст',
  },
  'VerificationModal:PurposeErrorText': {
    eng: 'Required field',
    ru: 'Поле обязательно для заполнения',
  },
  'VerificationModal:SendButtonText': {
    eng: 'Submit',
    ru: 'Отправить',
  },
  'VerificationModal:SuccessTitle': {
    eng: 'Your request was submitted',
    ru: 'Ваш запрос отправлен',
  },
  'VerificationModal:SuccessBody': {
    eng: 'We will review it within one business day and email the result at $[1]. The status of your account will change automatically.',
    ru: 'Мы проверим его в течение одного рабочего дня и отправим результат на почту $[1]. Статус вашего аккаунта изменится автоматически.',
  },
  'VerificationModal:SuccessButtonText': {
    eng: 'Got it',
    ru: 'Понятно',
  },
};
