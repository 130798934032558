import React, { FC, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../storeHooks';
import { Template } from '../../../../../reducers/templates.reducer/teplatesReducerTypes';
import { useLocation, useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { t } from 'localization';

import classes from './graphWayEndContainer.module.scss';
import { Button, InputText, Spinner, usePromiseProcessing } from '@just-ai/just-ui';
import { templateLangToLanguageName } from '../../../localization/lib';

import isAccess from '../../../../../isAccessFunction';
import ProjectService from '../../../../ProjectsApi/service/ProjectService';
import { GA } from '../../../../../pipes/pureFunctions';

import { createProject } from '../../../../../actions/botprojects.actions';
import { ProjectCreationData } from '../../../../ProjectsApi/service/helperClasses';
import { setCurrentProject } from '../../../../../actions/currentProject.actions';
import { BotType, CallsBotType } from '../../../../ProjectsApi/api/client';
import { bindActionCreators } from 'redux';
import history from 'appHistory';
import { useProjectNameHook } from '../../../shared/nameHook';
import { AppLogger } from '@just-ai/logger';
import { AxiosError } from 'axios';

const Detail: FC<{ name: string; value: string }> = ({ name, value }) => {
  return (
    <div className={classes.detail}>
      <b>{value}</b>
      <p className='mb-0'>{name}</p>
    </div>
  );
};

export const GraphWayEnd: FC<{}> = () => {
  const { templatesList, currentUser, voices } = useAppSelector(store => ({
    currentUser: store.CurrentUserReducer.currentUser,
    templatesList: store.TemplatesReducer.templatesList as Template[],
    voices: store.VoiceChannelReducer.voices,
  }));

  const location = useLocation<{ isCallBot?: boolean; callType?: string }>();

  const dispatch = useAppDispatch();
  const boundActionCreators = useMemo(
    () => bindActionCreators({ createProject, setCurrentProject }, dispatch),
    [dispatch]
  );
  const BotProjectsService = useMemo(() => new ProjectService(currentUser?.account?.id), [currentUser?.account?.id]);
  const params = useParams<{ templateLanguage: string; templateName: string }>();
  const templateToCreateFrom = useMemo(() => {
    return templatesList.find(
      template => template.language === params.templateLanguage && template.name === params.templateName
    );
  }, [params.templateLanguage, params.templateName, templatesList]);
  const isCallBot = useMemo(() => {
    return location.state?.isCallBot;
  }, [location.state]);

  const [beError, setBeError] = useState('');
  const { name, trimmedName, setNameHandler, triggerValidation, nameError } = useProjectNameHook();

  const submit = useCallback(async () => {
    if (templateToCreateFrom) {
      if (trimmedName.length === 0) {
        triggerValidation();
        return;
      }

      let projectObject: ProjectCreationData = {
        name: trimmedName,
        language: templateToCreateFrom.language,
        description: '',
        template: templateToCreateFrom.name,
        botType: BotType.CHATBOT,
        contentDirectory: '.',
        defaultBranch: 'master',
        voiceUniqueName: voices?.[0]?.uniqueName,
      };

      if (isAccess(['dialogs.cleanup'])) {
        projectObject = {
          ...projectObject,
          cleanupDialogs: false,
        };
      }
      if (isCallBot) {
        projectObject.botType = BotType.CALLSBOT;
        projectObject.callsBotType = location.state?.callType === 'DIALER' ? CallsBotType.DIALER : CallsBotType.IVR;
      }
      try {
        const {
          // @ts-ignore
          value: { data },
        } = await boundActionCreators.createProject(() =>
          BotProjectsService.createProject(projectObject).catch(e => {
            if (e instanceof AxiosError && e.response?.data) {
              setBeError(e.response.data.args?.message || e.response.data.message || e.message);
            }
          })
        );
        const isFromDefaultTemplate = templatesList.find(template => template.name === projectObject.template)
          ?.parsedJson.defaultTemplate;

        GA(
          'GAEvent',
          isFromDefaultTemplate ? 'create_bot_new' : 'create_bot_template',
          'created',
          isFromDefaultTemplate ? projectObject.language : projectObject.template
        );
        GA('GAEvent', isCallBot ? 'Calls_Project_Name_Next' : 'Chat_Project_Name_Next');

        localStorage.setItem(`BOT_CATEGORY_${data.id}`, 'later');
        boundActionCreators.setCurrentProject(data.shortName, data);

        history.push(`/scenario/${data.shortName}`);
      } catch (err) {
        if (err instanceof AxiosError) {
          AppLogger.error({
            exception: err,
            message: err.message,
          });
        }
      }
    }
  }, [
    BotProjectsService,
    boundActionCreators,
    isCallBot,
    location.state?.callType,
    templateToCreateFrom,
    templatesList,
    triggerValidation,
    trimmedName,
    voices,
  ]);

  const [submitState, runSubmit] = usePromiseProcessing(submit, {
    deps: [submit],
  });

  if (templateToCreateFrom)
    return (
      <div className={classes.graphWayEndContainer}>
        {submitState.loading && <Spinner size='sm' />}
        <h1 className='mt-3 mb-6'>{t('CreateProject:GraphWayEnd:Title')}</h1>
        <div className={classes.finalCard}>
          <h3 className='mb-3'>{t('CreateProject:GraphWayEnd:ProjectName:Label')}</h3>
          <InputText
            autoFocus
            fullWidth
            placeholder={t('CreateProject:GraphWayEnd:ProjectName:InputPlaceholder')}
            data-test-id='CreateProject:GraphWay:ProjectName'
            value={name}
            onChange={setNameHandler}
            invalid={nameError}
            errorText={nameError || beError || ''}
            maxLength={30}
          />
          <div className={classes.details}>
            <Detail
              name={t('CreateProject:GraphWayEnd:ProjectDetails:CommunicationTypeLabel')}
              value={
                isCallBot
                  ? t('CreateProject:GraphWayEnd:ProjectDetails:Call')
                  : t('CreateProject:GraphWayEnd:ProjectDetails:Chat')
              }
            />
            <Detail
              name={t('CreateProject:GraphWayEnd:ProjectDetails:CommunicationLanguage')}
              value={templateLangToLanguageName(templateToCreateFrom.language)}
            />
            <Detail name={t('CreateProject:GraphWayEnd:ProjectDetails:Template')} value={templateToCreateFrom.title} />
          </div>
          <Button
            className='d-block align-self-start'
            color='primary'
            onClick={runSubmit}
            disabled={submitState.loading}
            data-test-id='CreateProject:SkillWay:ButtonNext'
          >
            {t('CreateProject:GraphWayEnd:ProjectDetails:ButtonText')}
          </Button>
        </div>
      </div>
    );

  return <Redirect to='/404' />;
};
