export const demoblockedLocalization = {
  'DemoBlocked YandexPayment bold text': {
    eng: 'Your payment is being processed by $[1].',
    ru: 'Ожидаем подтверждения от платежной системы $[1].',
    pt: 'Seu pagamento está sendo processado por $[1].',
  },
  'DemoBlocked YandexPayment text': {
    eng: 'Please don’t worry, it won’t affect your current work',
    ru: 'Вы можете продолжать работу',
    pt: 'Não se preocupe, isso não afetará seu trabalho atual',
  },
  'DemoBlocked paymentUnsuccessful bold text': {
    eng: 'Your #{config.zenflow.productName} subscription has been paused',
    ru: 'Закончилась подписка на #{config.zenflow.productName}.',
    pt: 'Sua assinatura do #{config.zenflow.productName} foi pausada',
  },
  'DemoBlocked subscriptionExpires bold text': {
    eng: 'Your $[1] subscription is ending',
    ru: 'Заканчивается подписка на сервис $[1]',
    pt: 'Sua assinatura do $[1] está terminando',
  },
  'DemoBlocked paymentUnsuccessful button text': {
    eng: 'Pay now',
    ru: 'Оплатить подписку',
    pt: 'Pagar agora',
  },
  'DemoBlocked subscriptionExpires button text': {
    eng: 'Your #{config.zenflow.productName} subscription is ending',
    ru: 'Продлить подписку',
    pt: 'Sua assinatura do #{config.zenflow.productName} está terminando',
  },
  'DemoBlocked emailNotVerified bold text': {
    eng: 'Please verify your email to protect your account',
    ru: 'Подтвердите email для защиты вашего аккаунта',
    pt: 'Confirme seu e-mail para proteger sua conta',
  },
  'DemoBlocked emailNotVerified text': {
    eng: 'If you can’t find the email, please check the junk folder',
    ru: 'Проверьте спам, если письмо не пришло',
    pt: 'Se você não encontrar o e-mail, verifique a pasta de lixo eletrônico',
  },
  'DemoBlocked emailNotVerified button text': {
    eng: 'Send email',
    ru: 'Отправить письмо',
    pt: 'Enviar e-mail',
  },
  'DemoBlocked blocked bold text': {
    eng: 'Make a payment for #{config.zenflow.productName} subscription',
    ru: 'Внесите платеж за пользование сервисом',
    pt: 'Faça um pagamento pela assinatura do #{config.zenflow.productName}',
  },
  'DemoBlocked blocked button text': {
    eng: 'Purchase now',
    ru: 'Оплатить подписку',
    pt: 'Comprar agora',
  },
  'DemoBlocked YandexPaymentSuccess bold text': {
    eng: 'Payment is confirmed!',
    ru: 'Оплата подтверждена!',
    pt: 'O pagamento está confirmado!',
  },
  'DemoBlocked YandexPaymentSuccess text': {
    eng: 'Use with pleasure!',
    ru: 'Пользуйтесь с удовольствием!',
    pt: 'Use com satisfação!',
  },
  'DemoBlocked minutesPackageOver bold text': {
    eng: 'Your minutes are over.',
    ru: 'Минуты закончились.',
    pt: 'Seus minutos acabaram.',
  },
  'DemoBlocked minutesPackageOver text': {
    eng: 'The campaign is on pause now. Please, buy more minutes to continue campaign',
    ru: 'Обзвон поставлен на паузу до пополнения баланса минут',
    pt: 'A campanha está pausada agora. Compre mais minutos para continuar a campanha',
  },
  'DemoBlocked minutesPackageOver button text': {
    eng: 'Buy more minutes',
    ru: 'Купить минуты',
    pt: 'Comprar mais minutos',
  },
  'DemoBlocked minutesPackageNearLimit bold text $[1]': {
    eng: '$[1] left',
    ru: 'Осталось $[1].',
    pt: '$[1] restantes',
  },
  'DemoBlocked minutesPackageNearLimit text': {
    eng: 'When your balance is zero, the campaign will pause',
    ru: 'При нулевом балансе обзвон встанет на паузу',
    pt: 'Quando seu saldo for zero, a campanha será pausada',
  },
  'DemoBlocked minutesPackageNearLimit button text': {
    eng: 'Buy more minutes',
    ru: 'Купить минуты',
    pt: 'Comprar mais minutos',
  },
  'DemoBlocked minutesPackageLimit bold text $[1]': {
    eng: '$[1] left.',
    ru: 'Осталось $[1].',
    pt: '$[1] restantes.',
  },
  'DemoBlocked minutesPackageLimit text': {
    eng: 'When your balance is zero, the campaign will pause',
    ru: 'При нулевом балансе обзвон встанет на паузу',
    pt: 'Quando seu saldo for zero, a campanha será pausada',
  },
  'DemoBlocked minutesPackageLimit button text': {
    eng: 'Buy more minutes',
    ru: 'Купить минуты',
    pt: 'Comprar mais minutos',
  },
  'DemoBlocked demoExpires text': {
    eng: 'Your $[1] trial is ending',
    ru: 'Заканчивается демо-период на $[1]',
    pt: 'Sua avaliação do $[1] está terminando',
  },
  'DemoBlocked demoBlocked text': {
    eng: 'Please choose a subscription plan or request a payment agreement',
    ru: 'Выберите тариф или оставьте заявку на договор',
    pt: 'Escolha um plano de assinatura ou solicite um contrato de pagamento',
  },
  'DemoBlocked demoBlocked button 1 text': {
    eng: 'Subscription options',
    ru: 'Посмотреть тарифы',
    pt: 'Opções de assinatura',
  },
  'DemoBlocked demoExpires button text': {
    eng: 'Subscription options',
    ru: 'Посмотреть тарифы',
    pt: 'Opções de assinatura',
  },
  'DemoBlocked demoBlocked button 2 text': {
    eng: 'Agreement request',
    ru: 'Заявка на договор',
    pt: 'Solicitação de contrato',
  },
  'DemoBlocked demoBlocked bold text': {
    eng: 'The demo period is over.',
    ru: 'Демо-период закончился.',
    pt: 'O período de demonstração acabou.',
  },
  'DemoBlocked noSubscriptionCard text': {
    eng: 'A bank card is not added to your account. Your account will be blocked on $[1]',
    ru: 'Банковская карта не привязана к вашему аккаунту. $[1] ваш аккаунт будет заблокирован',
    pt: 'Um cartão bancário não está cadastrado na sua conta. Sua conta será bloqueada em $[1]',
  },
  'DemoBlocked noSubscriptionCard button text': {
    eng: 'Add card',
    ru: 'Привязать карту',
    pt: 'Adicionar cartão',
  },
  'DemoBlocked uniques one': {
    eng: 'user',
    ru: 'пользователь',
    pt: 'usuário',
  },
  'DemoBlocked uniques few': {
    eng: 'users',
    ru: 'пользователей',
    pt: 'usuários',
  },
  'DemoBlocked uniques many': {
    eng: 'users',
    ru: 'пользователей',
    pt: 'usuários',
  },
  'DemoBlocked uniques other': {
    eng: 'users',
    ru: 'пользователей',
    pt: 'usuários',
  },
  'DemoBlocked minutes one': {
    eng: 'minute',
    ru: 'минута',
    pt: 'minuto',
  },
  'DemoBlocked minutes few': {
    eng: 'minutes',
    ru: 'минуты',
    pt: 'minutos',
  },
  'DemoBlocked minutes many': {
    eng: 'minutes',
    ru: 'минут',
    pt: 'minutos',
  },
  'DemoBlocked minutes other': {
    eng: 'minutes',
    ru: 'минут',
    pt: 'minutos',
  },
  'DemoBlocked freeTariffLimitsWarning text': {
    eng: 'Limits left: $[1] $[2] and $[3] $[4] for telephony. To increase the limit, ',
    ru: 'Остаток лимитов: $[1] $[2] и $[3] $[4] для звонков. Для увеличения лимитов ',
    pt: 'Limites restantes: $[1] $[2] e $[3] $[4] para telefonia. Para aumentar o limite, ',
  },
  'DemoBlocked freeTariffLimitsWarning link': {
    eng: 'switch to another plan.',
    ru: 'выберите другой тариф.',
    pt: 'mude para outro plano.',
  },
  'DemoBlocked freeTariffLimitsDanger text': {
    eng: 'Limits left: $[1] $[2] and $[3] $[4] for telephony. To increase the limit, ',
    ru: 'Остаток лимитов: $[1] $[2] и $[3] $[4] для звонков. Для увеличения лимитов ',
    pt: 'Limites restantes: $[1] $[2] e $[3] $[4] para telefonia. Para aumentar o limite, ',
  },
  'DemoBlocked freeTariffLimitsDanger link': {
    eng: 'switch to another plan.',
    ru: 'выберите другой тариф.',
    pt: 'mude para outro plano.',
  },
};
