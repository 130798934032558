import React from 'react';
import { Modal } from '@just-ai/just-ui';

import { t } from 'localization';

type WarningOperatorModalProps = {
  onSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
};
function WarningAimychatOperatorModal({ isOpen, onSubmit, onCancel }: WarningOperatorModalProps) {
  return (
    <Modal
      title={t('OperatorSkill:WarningOperatorModal:Aimychat:Title')}
      buttonSubmitText={t('OperatorSkill:WarningOperatorModal:Aimychat:Submit')}
      buttonCancelText={t('OperatorSkill:WarningOperatorModal:Cancel')}
      buttonSubmitColor='primary'
      onCancelClick={onCancel}
      onActionClick={onSubmit}
      centered
      isOpen={isOpen}
    >
      <span>{t('OperatorSkill:WarningOperatorModal:Aimychat:Description')}</span>
    </Modal>
  );
}

export default React.memo(WarningAimychatOperatorModal);
