import { Component } from 'react';
import { AppLogger } from '@just-ai/logger';
import history from '../../appHistory';
import { axios, CancelToken, checkTariffRestrictions } from '../../pipes/functions';
import { GA } from '../../pipes/pureFunctions';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { addSnackbar } from '../../actions/snackbars.actions';
import { resendEmail } from '../../actions/register.actions';
import { bindActionCreators } from 'redux';
import localize, { t } from '../../localization';
import { AtreidesTypo, AtreidesIcon, Popover, withStyles } from 'altreidsds';
import { getPaymentData } from '../../actions/currentUser.actions';
import * as loginActions from '../../actions/currentUser.actions';

import moment from 'moment';
import { redirectToPaymentSystem } from 'pipes/paymentFunction';
import { demoblockedLocalization } from './localization/demoblocked.loc';
import { LimitBanner } from 'altreidsds';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';

localize.addTranslations(demoblockedLocalization);

const accountsadminService = new AccountsadminService();

let cancel = null;

class DemoBlocked extends Component {
  state = {
    show: false,
    type: false,
    text: '',
    buttonText: '',
    infoTooltipOpen: false,
    buttons: [],
  };

  attention = document.getElementById('attention');
  root = document.getElementById('root');

  requestPaymentJob = false;

  closed = false;
  anchorEl = null;

  componentWillUnmount() {
    if (this.requestPaymentJob && cancel) cancel('Operation canceled by the user.');
    window.removeEventListener('paymentWaiting', this.checkUserTariff);
    this.closeBanner();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.language !== nextProps.language) {
      this.setState({
        text: this.getText(this.state.type),
        buttonText: this.getButtons(this.state.type),
      });
    }
    if (this.props.isMobile !== nextProps.isMobile) {
      const currentClassName = this.props.isMobile ? 'mobileFulfilled' : 'fulfilled';
      const nextClassName = this.props.isMobile ? 'fulfilled' : 'mobileFulfilled';
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    window.addEventListener('paymentWaiting', this.checkUserTariff);
    this.checkUserTariff();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.state.type === 'YandexPayment' && !this.requestPaymentJob && this.props.currentUser) {
      let paymentId = localStorage.getItem(`${this.props.currentUser.email}_PAYMENT_ID`);
      this.requestPaymentJob = true;

      setTimeout(() => {
        if (paymentId) {
          this.startYandexPaymentJob(paymentId);
        } else {
          this.startYandexPaymentJob();
        }
      }, 10000);
    }

    if (!this.state.show) {
      this.checkUserTariff(prevState);
    } else {
      if (
        this.state.type &&
        ['freeTariffLimitsDanger', 'freeTariffLimitsWarning'].includes(this.state.type) &&
        this.props.currentUser &&
        prevProps.currentUser.tariff?.optionsData.currentUniqueUsers !==
          this.props.currentUser.tariff?.optionsData.currentUniqueUsers
      ) {
        this.recheckTariffLimits();
      }
    }

    if (this.state.infoTooltipOpen !== prevState.infoTooltipOpen) {
      this.setState({
        text: this.getText(this.state.type),
      });
    }
  }

  shouldShowLimitBanner = () => {
    const date = localStorage.getItem('limitBannerLastClosedAt');

    if (!date) return true;

    const savedDate = new Date(date);
    const currentDate = new Date();
    const differenceInMs = currentDate.getTime() - savedDate.getTime();
    const hoursInMs = 1000 * 60 * 60;
    const shouldBannerBeShown = differenceInMs >= 24 * hoursInMs;

    return shouldBannerBeShown;
  };

  showPopover = e => {
    this.anchorEl = e.target;
    this.setState({
      infoTooltipOpen: true,
    });
  };
  closePopover = () => {
    this.anchorEl = null;
    this.setState({
      infoTooltipOpen: false,
    });
  };

  startYandexPaymentJob = id => {
    const { currentUser } = this.props;

    if (!Boolean(id)) {
      id = Boolean(this.props.currentUser) ? this.props.currentUser.paymentProcessing.id : null;
    }
    this.props.actions
      .getPaymentData(currentUser.account.id, id, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(payload => {
        switch (payload.value.data.status) {
          case 'limits_updated_locally':
          case 'processed':
          case 'succeeded': {
            if (currentUser) {
              localStorage.removeItem(`${currentUser.email}_PAYMENT_ID`);
            }

            let event = new Event('YandexPaymentSuccess');
            window.dispatchEvent(event);

            this.requestPaymentJob = false;
            this.setShow('YandexPaymentSuccess');
            this.props.loginActions
              .getUserAccount()
              .then(payload => {
                GA('GAEvent', 'payment', 'success', payload.value.data.tariffName, payload.value.data.price);
                return this.props.loginActions.getManualControlInfo(currentUser.account.id);
              })
              .catch(AppLogger.createErrorHandler('getUserAccount'));
            break;
          }
          case 'canceled': {
            console.log('yookassa payment canceled'); // temp fix for cypress yookassa test
            if (payload.value.data.forSubscription) {
              if (currentUser) {
                localStorage.removeItem(`${currentUser.email}_PAYMENT_ID`);
              }

              let event = new Event('YandexPaymentSuccess');
              window.dispatchEvent(event);

              this.requestPaymentJob = false;
              this.setShow('YandexPaymentSuccess');
            } else {
              if (currentUser) {
                localStorage.removeItem(`${currentUser.email}_PAYMENT_ID`);
              }
              this.requestPaymentJob = false;
              if (!currentUser.subscription || currentUser.tariff?.blocked) {
                this.setShow('blocked');
              } else {
                this.closeBanner();
              }
            }
            break;
          }
          default:
            window.setTimeout(() => {
              this.startYandexPaymentJob();
            }, 5000);
        }
      });
  };

  recheckTariffLimits = () => {
    const {
      currentUser: {
        tariff: {
          tariffUniqueName,
          optionsData,
          limitsData: { uniqueUsers, nluSeconds, asrSeconds },
        },
      },
    } = this.props;
    const usedPackageUsers = optionsData?.maxUniqueUsersByPockets - uniqueUsers?.extraLimit;
    const usedUniqueUsers = optionsData?.currentUniqueUsers + usedPackageUsers;
    const maxUniqueUsers = optionsData?.maxUniqueUsers + optionsData?.maxUniqueUsersByPockets;
    const percentageUniqUsers = usedUniqueUsers / maxUniqueUsers;

    const percentageNluSeconds = nluSeconds.usedLimit / nluSeconds.tariffLimit;
    const percentageAsrSeconds = asrSeconds.usedLimit / asrSeconds.tariffLimit;
    const nluSecondsLeft = nluSeconds.tariffLimit - nluSeconds.usedLimit;
    const asrSecondsLeft = asrSeconds.tariffLimit - asrSeconds.usedLimit;
    const percentageMixedMinutes = nluSecondsLeft < asrSecondsLeft ? percentageNluSeconds : percentageAsrSeconds;

    if (tariffUniqueName === 'free') {
      if (percentageUniqUsers >= 0.8 || percentageMixedMinutes >= 0.8) {
        this.setShow('freeTariffLimitsDanger');
      } else {
        this.setShow('freeTariffLimitsWarning');
      }
      return true;
    }

    let percentageSeconds = optionsData?.currentSeconds / optionsData?.maxSeconds;
    if (percentageSeconds >= 1) {
      this.setShow('minutesPackageOver');
      return true;
    }

    if (percentageSeconds >= 0.9) {
      this.setShow('minutesPackageNearLimit');
      return undefined;
    }

    if (percentageSeconds >= 0.8) {
      this.setShow('minutesPackageLimit');
      return undefined;
    }
  };

  checkUserTariff = prevState => {
    const { currentUser } = this.props;
    const manualControl = currentUser?.tariff?.manualControl;
    if (
      Boolean(currentUser) &&
      Boolean(currentUser.paymentProcessing) &&
      Boolean(currentUser.paymentProcessing.status) &&
      (currentUser.paymentProcessing.status === 'waiting_for_capture' ||
        currentUser.paymentProcessing.status === 'pending') &&
      !this.closed
    ) {
      this.setShow('YandexPayment');
      return undefined;
    }

    if (this.props.currentUser && localStorage.getItem(`${this.props.currentUser.email}_PAYMENT_ID`) && !this.closed) {
      this.setShow('YandexPayment');
      return undefined;
    }

    if (checkTariffRestrictions(currentUser)) {
      return undefined;
    }

    if (
      Boolean(currentUser) &&
      Boolean(currentUser.tariff) &&
      (!Boolean(prevState) || (Boolean(prevState) && !Boolean(prevState.show)))
    ) {
      if (currentUser.tariff.blocked && Boolean(currentUser.subscription) && !currentUser.subscription.decline) {
        this.setShow('paymentUnsuccessful');
        return undefined;
      }

      if (!currentUser.tariff?.manualControl && !Boolean(currentUser.subscription) && currentUser.tariff?.price !== 0) {
        this.setShow('noSubscriptionCard');
        return undefined;
      }

      if (!currentUser.tariff.blocked) {
        let dateOfPaymentMoment = currentUser.tariff.blocked
          ? moment(currentUser.tariff.startDate).utc()
          : moment(currentUser.tariff.dueDate).utc();

        const nowMoment = moment();
        dateOfPaymentMoment = nowMoment > dateOfPaymentMoment ? nowMoment : dateOfPaymentMoment;
        const subscriptionDaysLeft = dateOfPaymentMoment.diff(nowMoment, 'days');

        if (subscriptionDaysLeft <= 7 && !manualControl) {
          if (
            currentUser.tariff.price > 0 &&
            (!currentUser.subscription || (Boolean(currentUser.subscription) && currentUser.subscription.decline))
          ) {
            this.setShow('subscriptionExpires');
            return undefined;
          }
          if (currentUser.tariff.demo) {
            this.setShow('demoExpires');
            return undefined;
          }
        }
      }

      if (currentUser.tariff.blocked && currentUser.tariff.demo && !manualControl) {
        this.setShow('demoBlocked');
        return undefined;
      }

      if (currentUser.tariff.blocked) {
        this.setShow('blocked');
        return undefined;
      }

      if (this.recheckTariffLimits()) return;

      if (
        this.props.currentUser &&
        !this.props.currentUser.emailVerified &&
        this.props.currentUser.emailVerificationRequired
      ) {
        this.setShow('emailNotVerified');
        return undefined;
      }
    }
  };

  handleBannerClick = (index = null) => {
    const {
      currentUser,
      actions: { addSnackbar },
    } = this.props;
    let tariff = currentUser.tariff;
    const { type } = this.state;

    switch (type) {
      case 'demoExpires': {
        GA('GAEvent', 'top_demo', 'clicked');

        history.push('/prices');
        break;
      }

      case 'demoBlocked': {
        GA('GAEvent', 'top_demo_over', 'clicked');
        if (index === 1) {
          history.push('/prices');
        } else {
          history.push('/contract');
        }
        break;
      }
      case 'blocked': {
        axios
          .post(`/restapi/billing/tariff/change`, {
            uniqueName: tariff.nextTariffUniqueName,
            redirectUrl: window.location.origin + '/personal?tab=0',
          })
          .then(({ data }) => {
            if (data.changed && !data.paymentId && !data.redirectUrl) {
              if (data.changed) {
                history.push('/personal?tab=0');
              } else {
                this.setState({
                  loading: false,
                });
              }
            } else {
              redirectToPaymentSystem(data, currentUser);
            }
          })
          .catch(e => {
            this.setState({
              loading: false,
            });
            const error = e?.response?.data?.error?.errorCode;
            if (error) {
              addSnackbar(t(`Payment:BE-error ${error}`), 'error');
            }
          });
        break;
      }

      case 'subscriptionExpires': {
        GA('GAEvent', 'top_plan', 'clicked');

        history.push('/prices');
        break;
      }

      case 'paymentUnsuccessful': {
        GA('GAEvent', 'top_plan_over', 'clicked');

        history.push('/personal?tab=0');
        break;
      }

      case 'noSubscriptionCard': {
        accountsadminService
          .createPaymentSubscription(currentUser.account.id, {
            redirectUrl: `${window.location.origin}/personal?tab=0`,
          })
          .then(({ data }) => {
            this.setState({
              loading: false,
            });
            redirectToPaymentSystem(data, currentUser);
          })
          .catch(e => {
            this.setState({
              loading: false,
            });

            this.props.actions.addSnackbar(Boolean(e.response.data.error) ? e.response.data.error : e.response.data);
          });
      }

      case 'minutesPackageOver':
      case 'minutesPackageLimit':
      case 'minutesPackageNearLimit': {
        history.push('/personal?tab=0');
        break;
      }
      case 'infoCalls': {
        GA('GAEvent', 'banner_calls', 'learn_more');
        window.open(t('Header how to create smart calls link'), '_blank');

        break;
      }

      case 'emailNotVerified': {
        if (this.state.emailVerifiedButtonFetch) return;

        this.setState({ emailVerifiedButtonFetch: true });

        this.props.actions.resendEmail(currentUser.id).then(() => {
          this.setState({
            buttons: [
              {
                onActionClick: () => {},
                actionText: this.getButtons('emailNotVerifiedSend'),
              },
            ],
          });
          this.props.actions.addSnackbar(t('Verification letter sent to your mail'));
        });

        break;
      }

      default: {
        history.push('/prices');

        break;
      }
    }
  };

  getText = type => {
    const { currentUser, classes } = this.props;
    let { optionsData, limitsData } = currentUser.tariff || {};
    const { infoTooltipOpen } = this.state;
    const rest = optionsData.maxSeconds - optionsData.currentSeconds;

    const usedPackageUsers = optionsData?.maxUniqueUsersByPockets - limitsData?.uniqueUsers?.extraLimit;
    const usedUniqueUsers = optionsData?.currentUniqueUsers + usedPackageUsers;
    const maxUniqueUsers = optionsData?.maxUniqueUsers + optionsData?.maxUniqueUsersByPockets;
    const uniqueUsersLeft = maxUniqueUsers - usedUniqueUsers;
    const nluSeconds = limitsData?.nluSeconds || {};
    const asrSeconds = limitsData?.asrSeconds || {};
    const nluSecondsLeft = nluSeconds.tariffLimit - nluSeconds.usedLimit + nluSeconds.extraLimit;
    const asrSecondsLeft = asrSeconds.tariffLimit - asrSeconds.usedLimit + asrSeconds.extraLimit;
    const mixedMinutesLeft = Math.floor((nluSecondsLeft < asrSecondsLeft ? nluSecondsLeft : asrSecondsLeft) / 60);

    switch (type) {
      case 'freeTariffLimitsDanger':
      case 'freeTariffLimitsWarning': {
        const intl = new Intl.PluralRules(currentUser.language);

        const uniquesDeclination = intl.select(uniqueUsersLeft);
        const minutesDeclination = intl.select(mixedMinutesLeft);

        return (
          <AtreidesTypo noMargin>
            {t(
              `DemoBlocked ${type} text`,
              uniqueUsersLeft,
              t(`DemoBlocked uniques ${uniquesDeclination}`),
              mixedMinutesLeft,
              t(`DemoBlocked minutes ${minutesDeclination}`)
            )}
            <NavLink to='/prices'>{t(`DemoBlocked ${type} link`)}</NavLink>
          </AtreidesTypo>
        );
      }
      case 'YandexPayment': {
        return (
          <AtreidesTypo noMargin>
            <AtreidesTypo type='b'>
              {t(
                `DemoBlocked ${type} bold text`,
                currentUser.tariff?.paymentSystem === 'STRIPE' ? 'Stripe' : t('yandex kassa')
              )}
            </AtreidesTypo>{' '}
            {t(`DemoBlocked ${type} text`)}
          </AtreidesTypo>
        );
      }
      case 'subscriptionExpires':
      case 'paymentUnsuccessful': {
        return (
          <AtreidesTypo noMargin>
            <AtreidesTypo type='b'>{t(`DemoBlocked ${type} bold text`)}</AtreidesTypo>
          </AtreidesTypo>
        );
      }
      case 'noSubscriptionCard': {
        return (
          <AtreidesTypo noMargin>
            {t(`DemoBlocked ${type} text`, moment(currentUser.tariff.dueDate).format('DD.MM.YYYY'))}
          </AtreidesTypo>
        );
      }
      case 'emailNotVerified': {
        return (
          <AtreidesTypo noMargin>
            <AtreidesTypo type='b'>{t(`DemoBlocked ${type} bold text`)}</AtreidesTypo>
            <AtreidesIcon
              onClick={this.showPopover}
              name='icon_iv2'
              className={classes.infoIcon}
              classes={{ color: classes.warning }}
            />
            <Popover
              data-test-id='DemoBlocked.popover'
              classes={{
                paper: classes.infoPopover,
              }}
              open={infoTooltipOpen}
              anchorEl={this.anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={this.closePopover}
              disableRestoreFocus
            >
              {' '}
              <AtreidesIcon
                onClick={this.closePopover}
                size='small'
                name='error'
                className={classes.popoverClose}
                color='primary'
              />
              <AtreidesTypo type='h3' noMargin data-test-id='DemoBlocked.header' variant='bold'>
                {t('What does account protection mean?')}
              </AtreidesTypo>
              <AtreidesTypo data-test-id='DemoBlocked.message'>
                {t('You will be able to restore your password and contact the support to manage your minutes balance')}
              </AtreidesTypo>
            </Popover>{' '}
            {t(`DemoBlocked ${type} text`)}
          </AtreidesTypo>
        );
      }
      case 'blocked': {
        return (
          <AtreidesTypo noMargin>
            <AtreidesTypo type='b'>{t(`DemoBlocked ${type} bold text`)}</AtreidesTypo>
          </AtreidesTypo>
        );
      }
      case 'YandexPaymentSuccess': {
        return (
          <AtreidesTypo noMargin>
            <AtreidesTypo type='b'>{t(`DemoBlocked ${type} bold text`)}</AtreidesTypo> {t(`DemoBlocked ${type} text`)}{' '}
            &#128515;
          </AtreidesTypo>
        );
      }
      case 'minutesPackageOver': {
        return (
          <AtreidesTypo noMargin>
            <AtreidesTypo type='b'>{t(`DemoBlocked ${type} bold text`)}</AtreidesTypo> {t(`DemoBlocked ${type} text`)}{' '}
          </AtreidesTypo>
        );
      }

      case 'minutesPackageNearLimit': {
        return (
          <AtreidesTypo noMargin>
            <AtreidesTypo type='b'>
              {t(
                `DemoBlocked ${type} bold text $[1]`,
                `${Math.floor(rest / 60)} ${t('min')} ${Math.floor(rest % 60) === 0 ? '00' : Math.floor(rest % 60)} ${t(
                  'sek'
                )}`
              )}
            </AtreidesTypo>{' '}
            {t(`DemoBlocked ${type} text`)}{' '}
          </AtreidesTypo>
        );
      }

      case 'minutesPackageLimit': {
        return (
          <AtreidesTypo noMargin>
            <AtreidesTypo type='b'>
              {t(
                `DemoBlocked ${type} bold text $[1]`,
                `${Math.floor(rest / 60)} ${t('min')} ${Math.floor(rest % 60) === 0 ? '00' : Math.floor(rest % 60)} ${t(
                  'sek'
                )}`
              )}
            </AtreidesTypo>{' '}
            {t(`DemoBlocked ${type} text`)}{' '}
          </AtreidesTypo>
        );
      }

      case 'demoBlocked': {
        return (
          <AtreidesTypo noMargin>
            <AtreidesTypo type='b'>{t(`DemoBlocked ${type} bold text`)}</AtreidesTypo>
            {t(`DemoBlocked ${type} text`)}
          </AtreidesTypo>
        );
      }

      default:
        return <AtreidesTypo noMargin>{t(`DemoBlocked ${type} text`)}</AtreidesTypo>;
    }
  };

  setShow = type => {
    if (!this.shouldShowLimitBanner()) return;
    this.setState({
      show: true,
      type: type,
      text: this.getText(type),
      buttonText: this.getButtons(type),
    });

    if (type === 'demoBlocked') {
      this.setState({
        buttons: [
          {
            onActionClick: () => this.handleBannerClick(1),
            actionText: this.getButtons(type, 1),
          },
          {
            onActionClick: () => this.handleBannerClick(2),
            actionText: this.getButtons(type, 2),
          },
        ],
      });
    }
  };

  closeBanner = () => {
    localStorage.setItem('limitBannerLastClosedAt', new Date().toISOString());

    const { currentUser } = this.props;
    if (this.root.classList.contains('changeSidebarFixed')) this.root.classList.remove('changeSidebarFixed');

    if (this.state.type === 'YandexPayment') {
      this.closed = true;
    }

    if (this.state.type === 'YandexPayment') {
      if (currentUser) {
        localStorage.removeItem(`${currentUser.email}_PAYMENT_ID`);
        this.recheckTariffLimits();
      }
    }

    if (this.state.type === 'infoCalls') {
      GA('GAEvent', 'banner_calls', 'close');
      this.closed = true;
      localStorage.setItem(`INFO_CALLS_DISABLED`, true);
    }

    this.setState({
      show: false,
      type: false,
      text: '',
      buttonText: '',
    });
  };

  getButtons = (type, index = null) => {
    switch (type) {
      case 'YandexPayment':
      case 'YandexPaymentSuccess': {
        return null;
      }

      case 'freeTariffLimitsDanger':
      case 'freeTariffLimitsWarning': {
        return null;
      }

      default: {
        if (Boolean(index)) {
          return t(`DemoBlocked ${type} button ${index} text`);
        } else if (type === 'emailNotVerifiedSend') {
          return t(`Send`);
        } else {
          return t(`DemoBlocked ${type} button text`);
        }
      }
    }
  };

  getHex = () => {
    const { currentUser } = this.props;
    if (currentUser) {
      const blendColors = (c0, c1, p) => {
        let f = parseInt(c0.slice(1), 16),
          t = parseInt(c1.slice(1), 16),
          R1 = f >> 16,
          G1 = (f >> 8) & 0x00ff,
          B1 = f & 0x0000ff,
          R2 = t >> 16,
          G2 = (t >> 8) & 0x00ff,
          B2 = t & 0x0000ff;
        return (
          0x1000000 +
          (Math.round((R2 - R1) * p) + R1) * 0x10000 +
          (Math.round((G2 - G1) * p) + G1) * 0x100 +
          (Math.round((B2 - B1) * p) + B1)
        )
          .toString(16)
          .slice(1);
      };
    }

    return false;
  };

  getColor = () => {
    switch (this.state.type) {
      case 'infoCalls': {
        return 'primary';
      }
      case 'YandexPaymentSuccess': {
        return 'success';
      }
      case 'subscriptionExpires':
      case 'demoExpires':
      case 'emailNotVerified':
      case 'minutesPackageLimit':
      case 'YandexPayment':
      case 'freeTariffLimitsWarning':
        return 'warning';
      default:
        return 'dangerous';
    }
  };

  render() {
    const { isMobile, classes } = this.props;
    const { show, type, text, buttonText } = this.state;

    if (!show) return null;

    return (
      <div style={{ gridArea: 'demo' }} data-test-id='DemoBlocked.LimitBanner'>
        <LimitBanner
          open
          onClose={
            [
              'demoBlocked',
              'blocked',
              'emailNotVerified',
              'usersDanger',
              'usersDangerFinal',
              'minutesPackageLimit',
              'minutesPackageNearLimit',
              'minutesPackageOver',
            ].includes(type) === false && this.closeBanner
          }
          className={isMobile ? classes.mobileBannerCustomClass : classes.bannerCustomClass}
          color={this.getColor()}
          hex={this.getHex()}
          content={text}
          actionText={buttonText}
          onActionClick={this.handleBannerClick}
          buttons={this.buttons}
        />
      </div>
    );
  }
}

DemoBlocked.propTypes = {};

const styles = theme => ({
  infoPopover: {
    width: 260,
    padding: '16px 8px 8px 16px',
  },
  warning: {
    fill: 'var(--warning)',
  },
  infoIcon: {
    marginLeft: 0,
  },
  popoverClose: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

function mapStateToProps(state) {
  return {
    currentUser: state.CurrentUserReducer.currentUser,
  };
}

const mapDispatcToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addSnackbar,
      resendEmail,
      getPaymentData,
    },
    dispatch
  ),
  loginActions: bindActionCreators(
    {
      ...loginActions,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatcToProps)(withStyles(styles, { withTheme: true })(DemoBlocked))
);
