import React, { useState, useCallback, useMemo, useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button, Spinner } from '@just-ai/just-ui';

import { t } from 'localization';
import { GA } from 'pipes/pureFunctions';

import ChatGPTBanner from 'components/ChatGPTBanner';
import useSyncCurrentProjectInState from 'helpers/hooks/useSyncCurrentProjectInState';
import { TestWidgetInstance } from 'services/TestWidget';
import { RealSkill } from 'modules/TemplatesWizard/types';
import { whichSkillCanBeAdded } from 'modules/TemplatesWizard/skillClasses/skillBuilder';
import PseudoWidget from 'modules/TemplatesWizard/pseudo-widget';
import { useProjectSkill } from 'modules/TemplatesWizard/hooks/useProjectSkill';
import { isChatGptEnabled } from 'modules/TemplatesWizard/access';

import { useAppContext } from 'contexts/AppContext';

import { WizardCard } from '../wizard-skill-card';

import classes from './skillsSelect.module.scss';

type SkillTestingType = { testedSkill: string; isWidgetOpened: boolean };

function usePreviewSkillsChatWidget() {
  const { projectSkillsService } = useAppContext();
  const testWidgetInstance = useMemo(() => new TestWidgetInstance(), []);
  const [testingSkillState, setTestingSkillState] = useReducer(
    (a: SkillTestingType, b: Partial<SkillTestingType>) => ({ ...a, ...b }),
    { testedSkill: '', isWidgetOpened: false }
  );

  const openTestWidgetForSkill = useCallback(
    async (skillName: string, lang: string) => {
      await testWidgetInstance.closeTestWidget();
      if (testingSkillState.testedSkill === skillName && testingSkillState.isWidgetOpened) {
        setTestingSkillState({ testedSkill: '', isWidgetOpened: false });
        return;
      }
      setTestingSkillState({ testedSkill: skillName, isWidgetOpened: true });
      PseudoWidget.TestWidgetLoading$.next(true);

      try {
        const testWidgetUrl = await projectSkillsService.getChatWidgetPreviewScriptUrl(skillName, lang);
        if (!testWidgetUrl) return;
        // noinspection ES6MissingAwait
        testWidgetInstance.openTestWidgetByUrl(testWidgetUrl, lang);
      } catch (e) {
        console.error('getChatWidgetPreviewScriptUrl error', e);
        setTestingSkillState({ testedSkill: '', isWidgetOpened: false });
      } finally {
        PseudoWidget.TestWidgetLoading$.next(false);
      }
      return () => testWidgetInstance.closeTestWidget();
    },
    [projectSkillsService, testWidgetInstance, testingSkillState]
  );
  useEffect(() => {
    const onOpenedUnsub = testWidgetInstance.subscribe('opened', isWidgetOpened => {
      setTestingSkillState({ isWidgetOpened });
    });
    const onMountedUnsub = testWidgetInstance.subscribe('mounted', isWidgetOpened => {
      PseudoWidget.TestWidgetLoading$.next(false);
    });
    return () => {
      onOpenedUnsub();
      onMountedUnsub();
    };
  }, [testWidgetInstance]);

  useEffect(
    () => () => {
      testWidgetInstance.closeTestWidget();
    },
    [testWidgetInstance]
  );

  return { testingSkillState, openTestWidgetForSkill };
}

export const SkillsSelectContainer = () => {
  const history = useHistory();
  const pageParams = useParams<{ projectShortName: string }>();
  useSyncCurrentProjectInState(pageParams.projectShortName);
  const { skillProject, registeredSkills, loading, skillProjectLang } = useProjectSkill(pageParams.projectShortName);
  const [selectedSkills, setSelectedSkills] = useState(() => new Set<string>());

  const availableSkills = useMemo(() => {
    if (!registeredSkills) return [];
    return whichSkillCanBeAdded([], Array.from(selectedSkills.values()) as RealSkill[], registeredSkills);
  }, [registeredSkills, selectedSkills]);

  const { testingSkillState, openTestWidgetForSkill } = usePreviewSkillsChatWidget();

  const onToggle = useCallback((name: RealSkill) => {
    setSelectedSkills(selected => {
      if (selected.has(name)) {
        GA(undefined, 'Skill_Remove', undefined, name);
        selected.delete(name);
      } else {
        GA(undefined, 'Skill_Select', undefined, name);
        selected.add(name);
      }
      return new Set(selected);
    });
  }, []);

  const goToBuildSKills = useCallback(async () => {
    GA(undefined, 'Selected_Skills_Next', undefined, Array.from(selectedSkills.values()).join(', '));
    history.push(`/wiz/skill/skills-select/${pageParams.projectShortName}/wizard`, {
      selectedSkills: Array.from(selectedSkills.values()),
    });
  }, [selectedSkills, history, pageParams.projectShortName]);

  useEffect(() => {
    if (skillProject && skillProject.skills.length > 0)
      history.push(`/wiz/skill/skills-select/${pageParams.projectShortName}/wizard`);
  }, [history, pageParams.projectShortName, skillProject]);

  if (!skillProjectLang) return <Spinner />;

  return (
    <div className={classes.SkillsSelectContainer}>
      {loading && <Spinner />}
      <h1>{t('SkillsSelectContainer:H1Title')}</h1>
      {isChatGptEnabled() && <ChatGPTBanner />}
      <div className={classes.SkillsSelectContainer_skillContainer}>
        {availableSkills.map(({ skillConfig, canFix, enabled, denyCauses }) => (
          <WizardCard
            key={skillConfig.skillName}
            title={skillConfig.title}
            denyCauses={denyCauses}
            isSpecial={skillConfig.isSpecial}
            description={skillConfig.shortDescription}
            selected={selectedSkills.has(skillConfig.skillName as RealSkill)}
            disabled={!enabled}
            canFix={canFix}
            withChatGPT={skillConfig.withChatGPT}
            skillId={skillConfig.skillName}
            startTest={() => openTestWidgetForSkill(skillConfig.skillName, skillProjectLang)}
            started={testingSkillState.testedSkill === skillConfig.skillName && testingSkillState.isWidgetOpened}
            onSelect={() => onToggle(skillConfig.skillName as RealSkill)}
            forceSelect={() =>
              setSelectedSkills(selected => {
                denyCauses?.forEach(el => selected.delete(el.skillName));
                selected.add(skillConfig.skillName);
                return new Set(selected);
              })
            }
          />
        ))}
      </div>
      <div>
        <Button
          data-test-id='SkillsSelectContainer:SetupSkills'
          color='primary'
          disabled={selectedSkills.size === 0}
          onClick={goToBuildSKills}
        >
          {t('SkillsSelectContainer:SetupSkills')}
        </Button>
      </div>
    </div>
  );
};
